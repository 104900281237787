import { StripeCardCvcElement, StripeElements } from '@stripe/stripe-js';
import React, { useEffect } from 'react';

interface CardCVCProps {
  disabled?: boolean;
  elements: StripeElements;
  onInit?: (cardNumber: StripeCardCvcElement) => void;
}

export function CardCVC(props: CardCVCProps) {
  const cardCvcRef: React.RefObject<HTMLDivElement> = React.createRef();

  useEffect(() => {
    const cardCvcElement = props.elements!.create('cardCvc', {
      placeholder: ''
    });
    cardCvcElement!.mount('#cardCvc');

    cardCvcElement.on('focus', () => {
      cardCvcRef!.current!.classList.remove('border-mint-medical-grey-light');
      cardCvcRef!.current!.classList.add(
        'border-mint-medical-green',
        'outline-2',
        'outline-mint-medical-green-lighter'
      );
    });

    cardCvcElement.on('blur', () => {
      cardCvcRef!.current?.classList.add('border-mint-medical-grey-light');
      cardCvcRef!.current?.classList.remove(
        'border-mint-medical-green',
        'outline-2',
        'outline-mint-medical-green-lighter'
      );
    });

    props.onInit && props.onInit(cardCvcElement);

    return () => {
      cardCvcElement.destroy();
    };
  }, [props]);

  return (
    <>
      <div
        className={
          !props.disabled
            ? 'h-12 w-full rounded-md border-2 border-mint-medical-grey-light p-3 text-base text-black outline-none placeholder:text-black focus:border-mint-medical-green focus:outline-2 focus:outline-mint-medical-green-lighter'
            : 'hidden'
        }
        ref={cardCvcRef}
        id="cardCvc"></div>

      <input
        disabled={true}
        value={'***'}
        className={
          props.disabled
            ? 'h-12 w-full rounded-md border-2 border-mint-medical-grey-light bg-[#F0F0F0] p-3 text-base text-black outline-none placeholder:text-black'
            : 'hidden'
        }></input>
    </>
  );
}

import { useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';

import {
  PaymentMethod,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent
} from '@stripe/stripe-js';

import { EditButton } from './EditButton';
import { get, post } from '../../../../libs/Requests';
import { InViewSpinner } from '../../../../components/Spinners/InViewSpinner';
import { DataLoader, LoadingStatus } from '../../../../types/DataLoader';
import { CardCVC } from '../../../order/components/OrderForm/CardCVC';
import { CardExpiry } from '../../../order/components/OrderForm/CardExpiry';
import { CardNumber } from '../../../order/components/OrderForm/CardNumber';

export enum OrganizationAddressFormMode {
  EditModeEnabled = 'edit-mode-enabled',
  OrganizationAddressEdited = 'organization-address-edited',
  OrganizationAddressSaved = 'organization-address-saved'
}

// interface OrganizationAddressDTO {
//   billing_details: {
//     address: object;
//   };
//   card: {
//     brand: string;
//     display_brand: string;
//     exp_month: number;
//     exp_year: number;
//     last4: string;
//   };
// }

export interface OrganizationAddressDataLoader extends DataLoader {
  data?: PaymentMethod[];
}

export function OrganizationAddressForm() {
  const [error, setError] = useState('');
  const [, setMode] = useState(OrganizationAddressFormMode.OrganizationAddressSaved);
  const [editable, setEditable] = useState(false);
  const [dataLoader, setDataLoader] = useState<OrganizationAddressDataLoader>({
    loadingStatus: LoadingStatus.Loading,
    data: []
  });

  const validity = {
    cardNumber: false,
    cvc: false,
    exp: false
  };

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    get('/company/cards').then(async (data) => {
      if (data.ok) {
        const cards = await data.json();

        setDataLoader({
          ...dataLoader,
          data: cards,
          loadingStatus: LoadingStatus.Succeed
        });
      }
    });
  }, []);

  function handleCardNumberChange(event: StripeCardNumberElementChangeEvent) {
    setError('');

    validity.cardNumber = event.complete && !event.error;
  }

  function handleCardCvcChange(event: StripeCardCvcElementChangeEvent) {
    setError('');

    validity.cvc = event.complete && !event.error;
  }

  function handleCardExpChange(event: StripeCardExpiryElementChangeEvent) {
    setError('');

    validity.exp = event.complete && !event.error;
  }

  async function submitForm() {
    if (!validity.cardNumber || !validity.cvc || !validity.exp) {
      return;
    }

    console.log('store data');

    let serverResponse;
    try {
      serverResponse = await post('/company/cards', '');

      serverResponse = await serverResponse.json();
    } catch (postCardError) {
      console.error(postCardError);
    }

    if (serverResponse && serverResponse.clientSecret) {
      const cardNumberElement = elements!.getElement('cardNumber');

      const card: PaymentMethod = dataLoader.data!.pop()!;

      await stripe!.confirmCardSetup(serverResponse.clientSecret, {
        payment_method: {
          // card: cardNumberElement!
          card: cardNumberElement!,
          billing_details: {
            name: card.billing_details.name || undefined,
            email: card.billing_details.email || undefined,
            address: {
              city: card.billing_details.address?.city || undefined,
              country: card.billing_details.address?.country || undefined,
              line1: card.billing_details.address?.line1 || undefined,
              line2: card.billing_details.address?.line2 || undefined,
              postal_code: card.billing_details.address?.postal_code || undefined,
              state: card.billing_details.address?.state || undefined
            }
          }
        }
      });
    }
  }

  if (dataLoader.loadingStatus === LoadingStatus.Loading || !dataLoader.data) {
    return <></>;
  }

  return (
    <div className="w-full">
      <div className="flex justify-between py-4">
        <span className="font-semibold">Payment Method</span>

        <EditButton
          disabled={dataLoader.loadingStatus !== LoadingStatus.Succeed}
          isInEditMode={editable}
          onCancel={() => {
            setMode(OrganizationAddressFormMode.OrganizationAddressSaved);
            setEditable(false);
          }}
          onSave={() => {
            console.log(validity);

            submitForm();
          }}
          onEdit={() => {
            setMode(OrganizationAddressFormMode.EditModeEnabled);
            setEditable(true);
          }}></EditButton>
      </div>

      {dataLoader.loadingStatus != LoadingStatus.Succeed || error ? (
        <div>{dataLoader.error || error || <InViewSpinner></InViewSpinner>}</div>
      ) : null}

      {dataLoader.loadingStatus === LoadingStatus.Succeed && stripe && elements ? (
        <div className="flex flex-col space-x-4 md:flex-row">
          <div className="w-full pr-2 md:w-1/2">
            <div>
              <div className="w-full">
                <label
                  className="text-xs font-semibold text-[#787878]"
                  htmlFor="cardNum">
                  Card Information
                </label>

                <CardNumber
                  onInit={(cardNumber) => {
                    cardNumber.on('change', (event) => {
                      handleCardNumberChange(event);
                    });
                  }}
                  disabled={!editable}
                  elements={elements}
                  last4={dataLoader.data!.at(0)!.card!.last4}></CardNumber>
              </div>
            </div>
            <div className="flex w-full flex-col space-x-4 md:flex-row">
              <div className="w-full">
                <label
                  className="text-xs font-semibold text-[#787878]"
                  htmlFor="cardCvc">
                  CVC
                </label>

                <CardCVC
                  onInit={(cardCvc) => {
                    cardCvc.on('change', (event) => {
                      handleCardCvcChange(event);
                    });
                  }}
                  elements={elements}
                  disabled={!editable}></CardCVC>
              </div>

              <div className="w-full">
                <label
                  className="text-xs font-semibold text-[#787878]"
                  htmlFor="cardExpiration">
                  MM / YY
                </label>

                <CardExpiry
                  onInit={(cardExp) => {
                    cardExp.on('change', (event) => {
                      handleCardExpChange(event);
                    });
                  }}
                  elements={elements}
                  cardExpiry={`${dataLoader.data?.at(0)?.card!.exp_month} / ${dataLoader.data?.at(0)?.card!.exp_year.toString().slice(2)}`}
                  disabled={!editable}></CardExpiry>
              </div>
            </div>

            <div className={editable ? ' block' : 'hidden'}>
              {/* <Address
                elements={elements}
                defaultValues={dataLoader.data?.at(0)?.billing_details}></Address> */}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

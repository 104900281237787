import React from 'react';

export interface EditButtonProps {
  disabled: boolean;
  isInEditMode: boolean;

  onCancel: () => void;
  onSave: () => void;
  onEdit: () => void;
}

export function EditButton(props: EditButtonProps) {
  return (
    <div className="flex flex-row space-x-4">
      <button
        disabled={props.disabled}
        className={props.isInEditMode ? 'font-semibold text-mint-medical-green hover:underline' : 'hidden'}
        onClick={() => {
          props.onCancel();
        }}>
        Cancel
      </button>

      <button
        disabled={props.disabled}
        className={props.isInEditMode ? 'font-semibold text-mint-medical-green hover:underline' : 'hidden'}
        onClick={() => {
          props.onSave();
        }}>
        Save
      </button>

      <button
        disabled={props.disabled}
        className={props.isInEditMode ? 'hidden' : 'font-semibold text-mint-medical-green hover:underline'}
        onClick={() => {
          props.onEdit();
        }}>
        Edit
      </button>
    </div>
  );
}

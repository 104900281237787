import React, { useState } from 'react';
import { Input } from '../../../../components/Input';
import { Link, useLocation } from 'react-router-dom';

interface SignInFormProps {
  submissionError: unknown;
  onSubmit: (formData: SignInDTO) => Promise<void>;
}

export interface SignInDTO {
  email: string;
  password: string;
}

export function SignInForm(props: SignInFormProps) {
  const location = useLocation();
  const [isDuringSubmission, setIsDuringSubmission] = useState<boolean>(false);
  const [userFormFields, setUserFormFields] = useState<SignInDTO>({
    email: '',
    password: ''
  });

  function handleChange(name: string, value: string) {
    setUserFormFields({ ...userFormFields, [name]: value });
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsDuringSubmission(true);

    props.onSubmit(userFormFields).finally(() => {
      setIsDuringSubmission(false);
    });
  }

  return (
    <>
      <form
        id="signInForm"
        onSubmit={handleSubmit}>
        <Input
          autoComplete="email"
          id="email"
          type="email"
          value={userFormFields.email}
          onChange={(event) => {
            handleChange('email', event.target.value);
          }}
          required={true}
          label="Email address"></Input>
        <Input
          autoComplete="current-password"
          id="password"
          type="password"
          value={userFormFields.password}
          onChange={(event) => {
            handleChange('password', event.target.value);
          }}
          required={true}
          label="Password"></Input>

        <div className="flex min-h-8 w-full">
          <span
            className={props.submissionError ? 'visible mx-auto self-center text-center text-red-500' : ' invisible'}>
            {props.submissionError as string}
          </span>
        </div>

        <div className="mx-auto w-3/6 pt-2">
          <Input
            disabled={isDuringSubmission}
            type="submit"
            value="Log In"></Input>
        </div>
      </form>

      <div className="py-4">
        <div className=" text-center">
          <Link
            className=" font-semibold text-mint-medical-green hover:underline"
            to={
              '/auth/reset_password' +
              (location.search ? '?returnUrl=' + encodeURIComponent([location.pathname, location.search].join('')) : '')
            }>
            Forgot your password?
          </Link>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from 'react';

import { CompanyDomainComponent } from '../CompanyDomain/CompanyDomain';
import { ICompanyDomain } from '../../../../types/CompanyDomain';
import { get, post } from '../../../../libs/Requests';
import { DataLoader, LoadingStatus } from '../../../../types/DataLoader';
import { InViewSpinner } from '../../../../components/Spinners/InViewSpinner';
import { EStatus } from '../../../../types/Status';
import { FullScreenSpinner } from '../../../../components/Spinners/FullScreenSpinner';

export interface CompanyDomainsDataLoader extends DataLoader {
  data?: ICompanyDomain[];
}

export enum CompanyDomainsMode {
  EditModeEnabled = 'edit-mode-enabled',
  CompanyDomainsEdited = 'company-domains-edited',
  CompanyDomainsSaved = 'company-domains-saved'
}

enum CompanyDomainAction {
  Add = 'add',
  Delete = 'delete',
  Update = 'update'
}

export interface CompanyDomainsProps {
  getEndpoint: string;
  postEndpoint: string;
  blockEditingStatus?: boolean;
}

export function CompanyDomains(props: CompanyDomainsProps) {
  const [saveDomainsError, setSaveDomainsError] = useState('');
  const GetEndpoint = props.getEndpoint || '/company/domain';
  const PostEndpoint = props.postEndpoint || '/company/domains';

  const [spinner, setSpinner] = useState(false);
  const [mode, setMode] = useState(CompanyDomainsMode.CompanyDomainsSaved);

  const [dataLoader, setDataLoader] = useState<CompanyDomainsDataLoader>({
    loadingStatus: LoadingStatus.Loading
  });

  const [domain, setDomain] = useState<string>('');
  const [fetchData, setFetchData] = useState<boolean>(true);

  function handleNewDomainChange(newDomain: string) {
    setDomain(newDomain.toLowerCase());
  }

  function handleCompanyDomainDelete(domainName: string) {
    const value = dataLoader.data?.find((domain) => domain.domainName === domainName);
    value!.action = CompanyDomainAction.Delete;

    setDataLoader({
      ...dataLoader,
      data: dataLoader.data
    });

    setMode(CompanyDomainsMode.CompanyDomainsEdited);
  }

  function handleExistingDomainUpdate(domainName: string, status: EStatus) {
    const value = dataLoader.data?.find((domain) => domain.domainName === domainName);
    if (!value!.action) {
      value!.action = CompanyDomainAction.Update;
    }
    value!.status = status;

    setDataLoader({
      ...dataLoader,
      data: dataLoader.data
    });

    setMode(CompanyDomainsMode.CompanyDomainsEdited);
  }

  function handleNewDomainFormSubmit(event: React.FormEvent) {
    event.preventDefault();

    const exists = dataLoader.data?.find((dm) => dm.domainName === domain);

    if (exists) {
      if (exists.action === CompanyDomainAction.Delete) {
        exists.action = undefined;

        setDataLoader({
          ...dataLoader,
          data: dataLoader.data
        });
      }
      setDomain('');

      return;
    }

    setMode(CompanyDomainsMode.CompanyDomainsEdited);

    const newDomain: ICompanyDomain = {
      domainName: domain,
      status: EStatus.Pending,
      action: CompanyDomainAction.Add
    };

    const newDomains: ICompanyDomain[] = [newDomain].concat(dataLoader.data!);

    setDataLoader({
      ...dataLoader,
      data: newDomains
    });

    setDomain('');
  }

  async function handleSaveClicked() {
    setSaveDomainsError('');

    try {
      setSpinner(true);

      const result = await post(PostEndpoint, JSON.stringify(dataLoader.data));

      if (result.status !== 200) {
        throw 'Some error during saving domains occurred. Please, try again later.';
      }

      setDataLoader({
        ...dataLoader,
        data: await result.json()
      });

      setMode(CompanyDomainsMode.CompanyDomainsSaved);

      setSpinner(false);
    } catch (error) {
      console.error(error);
      setSaveDomainsError(error as string);

      setSpinner(false);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setDataLoader({ loadingStatus: LoadingStatus.Loading });

    get(GetEndpoint, { signal })
      .then(async (domainsResp) => {
        const data = await domainsResp.json();

        setDataLoader({
          ...dataLoader,
          data: data as ICompanyDomain[],
          loadingStatus: LoadingStatus.Succeed
        });
      })
      .catch((error) => {
        setDataLoader({
          ...dataLoader,
          error: error.name === 'AbortError' ? undefined : 'Oops, some problems occurred during loading...',
          loadingStatus: LoadingStatus.Failed
        });
      });

    setFetchData(false);

    return () => {
      abortController.abort();
    };
  }, [fetchData]);

  return (
    <div className="flex w-full flex-col">
      {spinner && <FullScreenSpinner></FullScreenSpinner>}
      <div className="flex justify-between py-4">
        <span className="font-semibold">List of Organization Domains</span>
        <button
          className="font-semibold text-mint-medical-green hover:underline"
          onClick={() => {
            if (mode === CompanyDomainsMode.CompanyDomainsSaved) {
              setMode(CompanyDomainsMode.EditModeEnabled);
            } else if (mode === CompanyDomainsMode.EditModeEnabled) {
              setMode(CompanyDomainsMode.CompanyDomainsSaved);
            } else {
              handleSaveClicked();
            }
          }}>
          {mode === CompanyDomainsMode.EditModeEnabled && 'Cancel'}
          {mode === CompanyDomainsMode.CompanyDomainsEdited && 'Save'}
          {mode === CompanyDomainsMode.CompanyDomainsSaved && 'Edit'}
        </button>
      </div>

      {saveDomainsError && <div className="text-center text-red-500">{saveDomainsError}</div>}

      {mode !== CompanyDomainsMode.CompanyDomainsSaved && (
        <div
          className={
            dataLoader.loadingStatus != LoadingStatus.Succeed ? 'grid grid-cols-1' : 'grid grid-cols-1 md:grid-cols-2'
          }>
          <form
            className="flex w-full flex-row justify-end space-x-8 px-4 py-4"
            onSubmit={handleNewDomainFormSubmit}>
            <div className="my-auto w-4/5">
              <input
                onChange={(event) => {
                  handleNewDomainChange(event.target.value);
                }}
                className="divide-y divide-mint-medical-grey outline-none"
                pattern=".*\..+$"
                placeholder="domain.com"
                value={domain}></input>
              <div
                className={
                  !domain.match(/.*\..+$/)
                    ? 'h-px border-0 bg-mint-medical-grey'
                    : 'h-px border-0 bg-mint-medical-green'
                }></div>
            </div>
            <div className="my-auto flex self-end">
              <input
                type="submit"
                disabled={!domain.match(/.*\..+$/)}
                value={'Add'}
                className="my-auto place-self-end self-end text-end font-semibold text-mint-medical-green hover:underline disabled:text-mint-medical-grey"></input>
            </div>
          </form>
        </div>
      )}

      <div
        className={
          dataLoader.loadingStatus != LoadingStatus.Succeed ? 'grid grid-cols-1' : 'grid grid-cols-1 md:grid-cols-2'
        }>
        {dataLoader.loadingStatus != LoadingStatus.Succeed ? (
          <>{dataLoader.error || <InViewSpinner></InViewSpinner>}</>
        ) : null}

        {dataLoader.loadingStatus === LoadingStatus.Succeed
          ? dataLoader.data!.map((domain) => {
              if (domain.action === CompanyDomainAction.Delete) {
                return null;
              }

              return (
                <CompanyDomainComponent
                  blockEditingStatus={props.blockEditingStatus}
                  onDelete={(domainName) => {
                    handleCompanyDomainDelete(domainName);
                  }}
                  onStatusChange={(domainName, status) => {
                    handleExistingDomainUpdate(domainName, status as EStatus);
                  }}
                  showDeleteOption={mode !== CompanyDomainsMode.CompanyDomainsSaved}
                  key={domain.domainName}
                  domain={domain}></CompanyDomainComponent>
              );
            })
          : null}
      </div>
    </div>
  );
}

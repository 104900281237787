import React from 'react';
import { RouteObject } from 'react-router-dom';
import { MainLayout } from '../../../layout/MainLayout';
import { OrderPage } from '../pages/Order';

const orderPath = 'order';

const orderRoutes: RouteObject[] = [
  {
    path: '/',
    element: <MainLayout></MainLayout>,
    children: [
      {
        path: orderPath,
        element: <OrderPage></OrderPage>
      }
    ]
  }
];

export default orderRoutes;

export function getOrderTargetUrl(productId: string, seatsNumber: number) {
  const urlSearchParams = new URLSearchParams();

  urlSearchParams.set('product_id', productId);
  urlSearchParams.set('seats', seatsNumber.toString());

  const orderUrl = `/${orderPath}?` + urlSearchParams.toString();

  return orderUrl;
}

import React from 'react';

export interface LocalizedPriceProps {
  valueInCents: number;
  currency: string;
}

export function LocalizedPrice(props: LocalizedPriceProps) {
  return (
    <>
      {props.valueInCents < 0 ? '-' : ''}
      {props.currency === 'eur' ? '€' : props.currency === 'usd' ? '$' : ''}
      {(Math.abs(props.valueInCents) / 100).toFixed(2).toLocaleString()}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import logo from '../../../assets/mint_medical_logo.svg';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useStripe, useElements, CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { CompanyDataDTO, CompanyDataForm } from '../components/CompanyDataForm/CompanyDataForm';
import { SignUpOutput, confirmSignUp, signUp } from '@aws-amplify/auth';
import { SignUpState } from '../../auth/types';
import {
  SignUpConfirmationCodeDTO,
  SignUpConfirmationForm
} from '../../auth/components/SignUpConfirmationForm/SignUpConfirmationForm';
import { Description } from '../../../components/Description';
import { AuthCodeDeliveryDetails } from '@aws-amplify/auth/dist/esm/types';
import ColoredContainer from '../../../components/Containers/ColoredContainer';
import BorderedContainer from '../../../components/Containers/BorderedContainer';
import { post } from '../../../libs/Requests';
import { Address } from '../components/OrderForm/Address';
import { FullScreenSpinner } from '../../../components/Spinners/FullScreenSpinner';
import { useUserData } from '../../../hooks/useUserData';
import { useProductData } from '../../../hooks/useProductData';
import { useLanguage } from '../../../hooks/useLanguage';
import { HeadMetadata } from '../../../components/HeadMetadata';
import { Header, HeaderWrapper } from '../../../components/Header';

interface CustomerAddress {
  name: string;
  firstName?: string | undefined;
  lastName?: string | undefined;
  address: {
    line1: string;
    line2: string | null;
    city: string;
    state: string;
    postal_code: string;
    country: string;
  };
  phone?: string | undefined;
}

export interface SingUpDataDTO extends CompanyDataDTO {
  terms_agreement: string;
  billing_address: string;
  name_on_card: string;
}

export function OrderPage() {
  const { country } = useLanguage();
  const navigate = useNavigate();
  const loc = useLocation();

  const searchParams = loc.search ? new URLSearchParams(loc.search) : undefined;
  const { userData } = useUserData();
  const [cardCustomerId, setCardCustomerId] = useState();
  const [fullScreenSpinner, setFullScreenSpinner] = useState(false);
  const [cardCvcCompleted, setCardCvcCompleted] = useState(false);
  const [cardExpirationCompleted, setCardExpirationCompleted] = useState(false);
  const [cardNumberCompleted, setCardNumberCompleted] = useState(false);
  const [customerAddress, setCustomerAddress] = useState<null | CustomerAddress>();

  const [cardNumberFocus, setCardNumberFocus] = useState(false);
  const [cardCvcFocus, setCardCvcFocus] = useState(false);
  const [cardExpirationFocus, setCardExpirationFocus] = useState(false);

  const [signUpState, setSignUpState] = useState<SignUpState>(SignUpState.SIGN_UP);
  const [signUpEmail, setSignUpEmail] = useState('');
  const [signUpData, setSignUpData] = useState<AuthCodeDeliveryDetails>();
  const [formError, setFormError] = useState<unknown>('');
  const [signUpForm, setSignUpForm] = useState<SingUpDataDTO>({
    email: '',
    first_name: '',
    last_name: '',
    business_name: '',
    phone_number: '',
    password: '',
    domains: '',
    terms_agreement: '',
    billing_address: '',
    name_on_card: ''
  });
  const stripe = useStripe();
  const elements = useElements();

  const [productId, setProductId] = useState('');
  const [seats, setSeats] = useState(0);

  useEffect(() => {
    if (!searchParams || !searchParams!.get('product_id') || !searchParams!.get('seats')) {
      navigate('/');
    } else {
      setProductId(searchParams!.get('product_id')!);
      setSeats(parseInt(searchParams!.get('seats')!));
    }
  }, [searchParams]);

  const { productData, getTierName, calculateYearlyPriceForGivenSeats, getTierPrice, getCurrencySymbol } =
    useProductData({
      productId: productId,
      lang: 'en'
    });

  const SEOMetadata = {
    Title: productData.name,
    Description: productData.name + ' - ' + productData.description
  };

  function handleOnSignUp() {
    navigate('/auth/sign_in');
  }

  function handleSignUpNextSteps(output: SignUpOutput) {
    const { nextStep } = output;

    if (nextStep.signUpStep === SignUpState.DONE) {
      handleOnSignUp();
    }

    if (nextStep.signUpStep === SignUpState.CONFIRM_SIGN_UP) {
      setSignUpState(SignUpState.CONFIRM_SIGN_UP);

      setSignUpData(nextStep.codeDeliveryDetails);
    }
  }

  function handleChange(fieldName: string, fieldValue: string) {
    setSignUpForm({ ...signUpForm, [fieldName]: fieldValue });
  }

  async function handleSignUpConfirmation(formData: SignUpConfirmationCodeDTO) {
    setFormError('');

    const code = formData.code;

    try {
      const res = await confirmSignUp({
        username: signUpEmail,
        confirmationCode: code
      });

      handleSignUpNextSteps(res);
    } catch (error) {
      showFormError(error);
    }
  }

  function showFormError(error: unknown) {
    const errorText = `${error}`.split('tion: ').pop();

    setFormError(errorText);
  }

  async function handleFormSubmit(formData: CompanyDataDTO) {
    setFormError('');

    if (!cardCvcCompleted || !cardExpirationCompleted || !cardNumberCompleted || !customerAddress) {
      let _errorMessage = 'Missing card information. Please, check your ';
      const _missingFields = [];

      if (!cardNumberCompleted) {
        _missingFields.push('card number');
      }

      if (!cardCvcCompleted) {
        _missingFields.push('card CVC number');
      }

      if (!cardExpirationCompleted) {
        _missingFields.push('card expiration date');
      }

      if (!customerAddress) {
        _missingFields.push('address (all fields)');
      }

      _errorMessage +=
        _missingFields.length > 1
          ? _missingFields.slice(0, -1).join(', ') + ' and ' + _missingFields.at(-1)
          : _missingFields.at(-1);

      _errorMessage += '.';

      showFormError(_errorMessage);
      return;
    }

    if (userData && userData.groups.indexOf('ManagersOfCompanies') != -1) {
      /**
       * @todo add purchase here, create stripe user if needed, create card if needed, start a purchase
       */
      setFullScreenSpinner(true);
      console.log('purchase!');

      try {
        const anotherOrder = await post(
          '/order',
          JSON.stringify({
            company_email: userData.email,
            customer_address: customerAddress!.address,
            product_id: productId,
            product_seats_number: seats,
            customer_locale: country
          })
        );

        if (anotherOrder.status !== 201) {
          let errorMsg = 'Server error...';
          if (anotherOrder.status == 400) {
            errorMsg = (await anotherOrder.json()).error;
          }
          setFormError(errorMsg);
          setFullScreenSpinner(false);
          return;
        }

        const anotherOrderJsoned = await anotherOrder.json();
        if (!anotherOrderJsoned.clientSecret && !anotherOrderJsoned.subscriptionId) {
          navigate('/account');
          setFullScreenSpinner(false);
        }

        const cardNumberElement = elements!.getElement(CardNumberElement);

        const { error } = await stripe!.confirmCardPayment(anotherOrderJsoned.clientSecret, {
          payment_method: {
            card: cardNumberElement!,
            billing_details: {
              email: userData.email,
              address: {
                city: customerAddress.address.city,
                country: customerAddress.address.country,
                postal_code: customerAddress.address.postal_code,
                line1: customerAddress.address.line1,
                line2: customerAddress.address.line2 || undefined,
                state: customerAddress.address.state
              },
              name: formData.business_name
            }
          }
        });

        if (error) {
          console.log(error);

          setFormError(error!.message);
          setFullScreenSpinner(false);
          return;
        }

        setFullScreenSpinner(false);
        navigate('/account');
      } catch (orderError) {
        console.log(orderError);
      }

      return;
    }

    setFullScreenSpinner(true);

    let customerId;

    if (!cardCustomerId) {
      const creationHoldOnCreditCard = await post(
        '/create_amount_hold_on_card',
        JSON.stringify({
          company_email: formData.email,
          company_name: formData.business_name,
          company_phone: formData.phone_number,
          customer_address: customerAddress!.address,
          product_id: productId,
          product_seats_number: seats,
          customer_locale: country
        })
      );
      const creditCardHold = await creationHoldOnCreditCard.json();
      const cardNumberElement = elements!.getElement(CardNumberElement);

      // Use card Element to tokenize payment details
      const { error } = await stripe!.confirmCardPayment(creditCardHold.clientSecret, {
        payment_method: {
          card: cardNumberElement!,
          billing_details: {
            email: formData.email,
            address: {
              city: customerAddress.address.city,
              country: customerAddress.address.country,
              postal_code: customerAddress.address.postal_code,
              line1: customerAddress.address.line1,
              line2: customerAddress.address.line2 || undefined,
              state: customerAddress.address.state
            },
            name: formData.business_name
          }
        }
      });

      if (error) {
        console.log(error);

        setFormError(error!.message);
        setFullScreenSpinner(false);
        return;
      }

      setCardCustomerId(creditCardHold.customerId);

      customerId = creditCardHold.customerId;
    }

    try {
      const resp = await signUp({
        username: formData.email,
        password: formData.password,
        options: {
          userAttributes: {
            given_name: formData.first_name,
            family_name: formData.last_name,
            email: formData.email,
            'custom:companyPayload': JSON.stringify({
              company_name: formData.business_name,
              company_domains: formData.domains,
              company_stripe_customer_id: customerId,
              company_seats_number: seats,
              phone_number: formData.phone_number
            })
          }
        }
      });

      setSignUpEmail(formData.email);

      handleSignUpNextSteps(resp);

      setFullScreenSpinner(false);
    } catch (error) {
      const errorText = `${error}`.split('tion: ').pop();

      setFormError(errorText);

      setFullScreenSpinner(false);
    }
  }

  if (signUpState === SignUpState.CONFIRM_SIGN_UP) {
    return (
      <>
        <Description>
          <>
            We have delivered the authentication code by {signUpData?.deliveryMedium} to {signUpData?.destination}.
            Please enter the code below to complete authentication.
          </>
        </Description>

        <div className="mx-auto w-full bg-white md:w-2/6">
          <SignUpConfirmationForm
            submissionError={formError}
            onSubmit={handleSignUpConfirmation}></SignUpConfirmationForm>
        </div>
      </>
    );
  }

  if (userData?.groups && userData?.groups?.length > 0) {
    if (userData?.groups.indexOf('ManagersOfCompanies') === -1) {
      return (
        <Navigate
          to="/account"
          replace></Navigate>
      );
    }
  }

  return stripe && elements ? (
    <>
      <HeadMetadata
        title={SEOMetadata.Title}
        description={SEOMetadata.Description}></HeadMetadata>

      <HeaderWrapper>
        <Header>{productData.name} - Purchase Your Plan</Header>
      </HeaderWrapper>

      <div className="mt-8">
        {fullScreenSpinner ? <FullScreenSpinner></FullScreenSpinner> : null}
        <BorderedContainer>
          <div className="">
            <div className="flex flex-col justify-between self-start md:flex-row">
              <img
                className="w-3/4 self-center py-4 md:w-1/6 md:self-start"
                src={logo}></img>
            </div>
            <div className="flex flex-row justify-between self-start">
              <span className=" my-auto flex flex-col text-wrap pr-1 text-base font-semibold text-mint-medical-grey md:flex-row">
                Number of users for your plan
              </span>
              <input
                id="seats"
                disabled={true}
                className="w-12 rounded-md border-2 border-mint-medical-green-lighter p-2 text-center focus:border-mint-medical-green focus:outline-none md:w-24"
                value={seats}
                type="number"></input>
            </div>
            <div>
              <div className={'my-auto flex w-full flex-row justify-between self-start pb-2'}>
                <span className="pl-0 text-base font-semibold text-mint-medical-grey md:pl-4">
                  {productData.name} | {getTierName(seats)}
                </span>
                <span className="text-base font-semibold">
                  {getCurrencySymbol()}
                  {getTierPrice(seats).replaceAll('.00', '')} per user/month
                </span>
              </div>
            </div>
            <div>
              <div className="flex justify-between">
                <span className="my-auto text-base font-semibold text-mint-medical-grey">Total</span>
                <span className="text-base font-semibold">
                  {calculateYearlyPriceForGivenSeats(seats)} billed annually
                </span>
              </div>
            </div>
          </div>

          <div className="text-md py-4 text-justify font-semibold text-[#787878]">{productData.description}</div>
        </BorderedContainer>

        {!userData?.email && (
          <>
            <div className="py-4 text-center text-xl font-semibold text-mint-medical-green">
              <Link to="/auth/sign_in">Already have an account? Sign in</Link>
            </div>
            <div className="text-md py-4 text-center">or</div>
            <div className="text-center text-[#282829]">
              Enter your company contact information. Please provide an email address where we should send your licenses
              to:
            </div>
          </>
        )}

        <CompanyDataForm
          hideCompanyFields={userData && userData.groups.indexOf('ManagersOfCompanies') != -1}
          submitLabel="Request Subscription"
          onFormFieldChange={(name, value) => {
            handleChange(name, value);
          }}
          onSubmit={handleFormSubmit}
          submissionError={formError}>
          <div className="py-4 text-base font-bold">Payment Method</div>
          <div className="flex w-full flex-col-reverse md:flex-row md:space-x-4">
            <div className="w-full md:w-1/2">
              <label
                className="text-xs font-semibold text-[#787878]"
                htmlFor="cardNum">
                Card Information
              </label>
              <CardNumberElement
                id="cardNum"
                options={{
                  placeholder: '',
                  showIcon: true
                }}
                onFocus={() => {
                  setCardNumberFocus(true);
                }}
                onBlur={() => {
                  setCardNumberFocus(false);
                }}
                onChange={(event) => {
                  if (event.complete) {
                    setCardNumberCompleted(true);
                  } else {
                    setCardCvcCompleted(false);
                  }
                }}
                className={
                  cardNumberFocus
                    ? 'h-12 w-full rounded-md border-2 border-mint-medical-green p-3 text-base text-black outline-none outline-2 outline-mint-medical-green-lighter disabled:bg-[#F0F0F0] disabled:text-black'
                    : 'h-12 w-full rounded-md border-2 border-[#D2D2D2] p-3 text-base text-black outline-none focus:border-mint-medical-green focus:outline-2 focus:outline-mint-medical-green-lighter disabled:bg-[#F0F0F0] disabled:text-black'
                }></CardNumberElement>

              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="w-full md:w-1/2">
                  <label
                    className="text-xs font-semibold text-[#787878]"
                    htmlFor="cardCvc">
                    CVC
                  </label>
                  <CardCvcElement
                    onBlur={() => {
                      setCardCvcFocus(false);
                    }}
                    onFocus={() => {
                      setCardCvcFocus(true);
                    }}
                    options={{
                      placeholder: ''
                    }}
                    onChange={(event) => {
                      if (event.complete) {
                        setCardCvcCompleted(true);
                      } else {
                        setCardCvcCompleted(false);
                      }
                    }}
                    id="cardCvc"
                    className={
                      cardCvcFocus
                        ? 'h-12 w-full rounded-md border-2 border-mint-medical-green p-3 text-base text-black outline-none outline-2 outline-mint-medical-green-lighter disabled:bg-[#F0F0F0] disabled:text-black'
                        : 'h-12 w-full rounded-md border-2 border-[#D2D2D2] p-3 text-base text-black outline-none disabled:bg-[#F0F0F0] disabled:text-black'
                    }></CardCvcElement>
                </div>

                <div className="w-full md:w-1/2">
                  <label
                    className="text-xs font-semibold text-[#787878]"
                    htmlFor="cardExpiration">
                    MM / YY
                  </label>
                  <CardExpiryElement
                    options={{
                      placeholder: ''
                    }}
                    onBlur={() => {
                      setCardExpirationFocus(false);
                    }}
                    onFocus={() => {
                      setCardExpirationFocus(true);
                    }}
                    onChange={(event) => {
                      if (event.complete) {
                        setCardExpirationCompleted(true);
                      } else {
                        setCardExpirationCompleted(false);
                      }
                      console.log(event);
                    }}
                    id="cardExpiration"
                    className={
                      cardExpirationFocus
                        ? 'h-12 w-full rounded-md border-2 border-mint-medical-green p-3 text-base text-black outline-none outline-2 outline-mint-medical-green-lighter disabled:bg-[#F0F0F0] disabled:text-black'
                        : 'h-12 w-full rounded-md border-2 border-mint-medical-grey-light p-3 text-base text-black outline-none disabled:bg-[#F0F0F0] disabled:text-black'
                    }></CardExpiryElement>
                </div>
              </div>

              <Address
                onInit={(address) => {
                  address.on('change', (event) => {
                    if (event.complete) {
                      setCustomerAddress(event.value);
                    } else {
                      setCustomerAddress(null);
                    }
                  });
                }}
                elements={elements}></Address>
            </div>
            <div className="w-full md:w-1/2">
              <ColoredContainer>
                <div className="text-center font-semibold">Credit Card Authorization Notice</div>
                <ul className=" m-4 list-disc space-y-2 text-base font-thin">
                  <li>
                    Pre-Authorization: We&apos;re performing a secure pre-authorization check on your card. This is not
                    a charge.
                  </li>
                  <li>
                    Temporary Hold: A small temporary hold (e.g., {getCurrencySymbol()}1) may appear on your statement
                    but won&apos;t be charged.
                  </li>
                  <li>Approval Required: Your card will only be charged after we approve your account.</li>
                  <li>
                    Expiration of Hold: If account approval takes longer than 7 days, the hold may expire, and we might
                    need to re-authorize your card.
                  </li>
                  <li>Payment Security: Your card details are encrypted for your security.</li>
                  <li>We reserve the rights to deny order requests. </li>
                </ul>
              </ColoredContainer>
            </div>
          </div>
          <div className=" pt-4 font-semibold text-mint-medical-grey">
            By confirming your subscription, you allow Mint Medical to charge your card for this payment and future
            payments in accordance with their terms. You can always cancel your subscription. View{' '}
            <Link
              target="blank"
              className=" text-mint-medical-green"
              to="/document/terms_and_conditions">
              Terms
            </Link>{' '}
            and{' '}
            <Link
              target="blank"
              className=" text-mint-medical-green"
              to="/document/privacy_policy">
              Privacy
            </Link>
            .
          </div>
          <div className="space-x-4 pt-4">
            <input
              id="terms_agreement"
              name="terms_agreement"
              type="checkbox"
              onChange={(event) => {
                handleChange(event.target.name, event.target.value);
              }}
              required
              value="1"></input>
            <label
              htmlFor="terms_agreement"
              className=" font-semibold text-mint-medical-grey">
              I agree the{' '}
              <Link
                className=" text-mint-medical-green"
                target="blank"
                to="/document/terms_and_conditions">
                terms and conditions
              </Link>
              .
            </label>
          </div>
        </CompanyDataForm>
      </div>
    </>
  ) : (
    <></>
  );
}

import React from 'react';
import { ChildrenOwnerProps } from '../Base';

export interface TextAreaProps extends ChildrenOwnerProps {
  id?: string;
  autoComplete?: string;
  disabled?: boolean;
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  maxlength?: number;
  required?: boolean;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
}

export function TextArea(props: TextAreaProps) {
  return (
    <div className="w-full">
      {props.label && (
        <label
          className="text-xs font-semibold text-[#787878]"
          htmlFor={props.id}>
          {props.label}
        </label>
      )}
      <textarea
        id={props.id}
        autoComplete={props.autoComplete}
        name={props.name}
        required={props.required}
        placeholder={props.placeholder}
        className={
          'h-fit w-full rounded-md border-2 border-mint-medical-grey-light p-4 text-base text-black outline-none focus:border-mint-medical-green focus:outline-2 focus:outline-mint-medical-green-lighter disabled:bg-[#F0F0F0] disabled:text-black'
        }
        maxLength={props.maxlength}
        disabled={props.disabled}
        onChange={props.onChange}
        value={props.value}></textarea>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { get } from '../libs/Requests';
import { StoreProductDTO } from '../types/StoreProduct';
import { useLanguage } from './useLanguage';

export interface ProductsDataHooksProps {
  productId: string;
  lang: string;
}

const cachedProductsMap = new Map<string, StoreProductDTO>();

export function useProductData(props: ProductsDataHooksProps) {
  const { country } = useLanguage();
  const [productData, setProductData] = useState<StoreProductDTO>({
    name: '',
    shortName: '',
    currency: '',
    tiers: []
  });

  useEffect(() => {
    const productUrl = `/product_tiers?lang=${props.lang}&product_short_name=${props.productId}&user_locale=${country}`;
    const cachedProductData = cachedProductsMap.get(productUrl);

    if (cachedProductData) {
      setProductData(cachedProductData);
    } else {
      const abortController = new AbortController();
      const signal = abortController.signal;

      get(productUrl, { signal })
        .then(async (response) => {
          const data = await response!.json();

          setProductData(data);

          cachedProductsMap.set(productUrl, data);
        })
        .catch(() => {});
    }
  }, []);

  function _findTier(seatsNumber: number) {
    const tier = productData?.tiers
      .filter((t) => {
        return (
          (!t.seatsRange!.to && seatsNumber >= t.seatsRange.from) ||
          (seatsNumber >= t.seatsRange.from && seatsNumber <= t.seatsRange!.to!)
        );
      })
      .pop();

    return tier;
  }

  function calculateYearlyPriceForGivenSeats(seatsNumber: number): string {
    const tier = _findTier(seatsNumber);

    if (!tier) {
      return '';
    }

    if (tier.currency === 'eur') {
      return `€${parseInt(tier.priceByYear!) * seatsNumber} EUR`;
    }

    if (tier.currency === 'usd') {
      return `$${parseInt(tier.priceByYear!) * seatsNumber} USD`;
    }

    return '';
  }

  function getCurrencySymbol() {
    if (productData.currency === 'usd') {
      return '$';
    }

    if (productData.currency === 'eur') {
      return '€';
    }

    return '';
  }

  function getTierPrice(seatsNumber: number): string {
    const tier = _findTier(seatsNumber);

    if (!tier) {
      return '';
    }

    return tier.priceByMonth!;
  }

  function getTierName(seatsNumber: number): string {
    const tier = _findTier(seatsNumber);

    if (!tier) {
      return '';
    }

    return tier?.seatsRange.to
      ? `${tier?.seatsRange.from}-${tier?.seatsRange.to} Users Tier`
      : `${tier?.seatsRange.from}+ Users Tier`;
  }

  return { productData, getTierName, getTierPrice, calculateYearlyPriceForGivenSeats, getCurrencySymbol };
}

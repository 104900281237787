// import { CardNumberElement } from '@stripe/react-stripe-js';
import { StripeCardNumberElement, StripeElements } from '@stripe/stripe-js';
import React, { useEffect } from 'react';

interface CardNumberProps {
  disabled?: boolean;
  elements: StripeElements;
  last4?: string;
  onInit?: (cardNumber: StripeCardNumberElement) => void;
}

export function CardNumber(props: CardNumberProps) {
  const cardNumberRef: React.RefObject<HTMLDivElement> = React.createRef();

  useEffect(() => {
    const cardNumberElement = props.elements!.create('cardNumber', {
      showIcon: true,
      placeholder: ''
    });
    cardNumberElement!.mount('#cardNumber');

    cardNumberElement.on('focus', () => {
      cardNumberRef!.current!.classList.remove('border-mint-medical-grey-light');
      cardNumberRef!.current!.classList.add(
        'border-mint-medical-green',
        'outline-2',
        'outline-mint-medical-green-lighter'
      );
    });

    cardNumberElement.on('blur', () => {
      cardNumberRef!.current?.classList.add('border-mint-medical-grey-light');
      cardNumberRef!.current?.classList.remove(
        'border-mint-medical-green',
        'outline-2',
        'outline-mint-medical-green-lighter'
      );
    });

    props.onInit && props.onInit(cardNumberElement);

    return () => {
      cardNumberRef?.current?.classList.add('border-mint-medical-grey-light');
      cardNumberRef?.current?.classList.remove(
        'border-mint-medical-green',
        'outline-2',
        'outline-mint-medical-green-lighter'
      );

      cardNumberElement.destroy();
    };
  }, [props]);

  console.log(props);

  return (
    <>
      <div
        className={
          !props.disabled
            ? 'h-12 w-full rounded-md border-2 border-mint-medical-grey-light p-3 text-base text-black outline-none placeholder:text-black focus:border-mint-medical-green focus:outline-2 focus:outline-mint-medical-green-lighter'
            : 'hidden'
        }
        ref={cardNumberRef}
        id="cardNumber"></div>

      <input
        disabled={true}
        value={`**** **** **** ${props.last4}`}
        className={
          props.disabled
            ? 'h-12 w-full rounded-md border-2 border-mint-medical-grey-light bg-[#F0F0F0] p-3 text-base text-black outline-none placeholder:text-black'
            : 'hidden'
        }></input>
    </>
  );
}

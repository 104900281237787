import React, { useState } from 'react';
import { Input } from '../../../../components/Input';
import { updatePassword } from '@aws-amplify/auth';
import { FullScreenSpinner } from '../../../../components/Spinners/FullScreenSpinner';

interface FormFields {
  oldPassword: string;
  newPassword: string;
  reenteredNewPassword: string;
}

export function PasswordEditForm() {
  const [duringUpdate, setDuringUpdate] = useState(false);
  const [error, setError] = useState('');
  const [inEditMode, setInEditMode] = useState(false);
  const [fieldChanged, setFieldChanged] = useState(false);
  const [formFields, setFormFields] = useState<FormFields>({
    oldPassword: '****************',
    newPassword: '',
    reenteredNewPassword: ''
  });

  function handleChange(fieldName: string, fieldValue: string) {
    setFormFields({
      ...formFields,
      [fieldName]: fieldValue
    });
  }

  async function handleEditClick() {
    setError('');

    if (inEditMode) {
      if (!fieldChanged) {
        setFormFields({
          ...formFields,
          oldPassword: '****************'
        });

        setInEditMode(!inEditMode);
        return;
      }

      if (formFields.newPassword.length && formFields.newPassword !== formFields.reenteredNewPassword) {
        setError('Passwords must be the same.');

        return;
      }

      if (fieldChanged) {
        try {
          setDuringUpdate(true);

          await updatePassword({ oldPassword: formFields.oldPassword, newPassword: formFields.newPassword });

          setFormFields({
            oldPassword: '****************',
            newPassword: '',
            reenteredNewPassword: ''
          });

          setFieldChanged(false);

          setInEditMode(!inEditMode);

          setDuringUpdate(false);
          return;
        } catch (err) {
          let [errorType, errorMessage] = `${err}`.split(':');
          errorType = errorType.toLowerCase();

          if (errorType! === 'notauthorizedexception') {
            errorMessage = 'Your current password probably was invalid.';
          }

          setError(errorMessage!);

          setDuringUpdate(false);

          return;
        }
      }
    } else {
      setFormFields({
        ...formFields,
        oldPassword: ''
      });

      setInEditMode(!inEditMode);
    }
  }

  return (
    <>
      {duringUpdate && <FullScreenSpinner></FullScreenSpinner>}
      <div className="flex justify-between py-4">
        <span className="font-semibold">Password</span>
        <input
          className="cursor-pointer font-semibold text-mint-medical-green hover:underline"
          type={fieldChanged ? 'submit' : 'button'}
          form={fieldChanged ? 'passwordEditForm' : ''}
          onClick={fieldChanged ? undefined : handleEditClick}
          value={inEditMode ? (fieldChanged ? 'Save' : 'Cancel') : 'Edit'}></input>
      </div>
      <form
        id="passwordEditForm"
        onSubmit={(event) => {
          event.preventDefault();

          handleEditClick();
        }}>
        <div
          className={
            inEditMode ? 'flex w-1/2 flex-col pr-2 md:flex-row md:space-x-4' : 'flex w-1/2 flex-col pr-2 md:flex-row'
          }>
          <Input
            type="password"
            disabled={!inEditMode}
            id="oldPassword"
            name="oldPassword"
            autoComplete="current-password"
            required
            onChange={(event) => {
              setFieldChanged(true);

              handleChange(event.target.name, event.target.value);
            }}
            value={formFields.oldPassword}
            label={inEditMode ? 'Current Password' : 'Password'}></Input>
        </div>
        <div className={inEditMode ? 'flex flex-col md:flex-row md:space-x-4' : 'hidden'}>
          <Input
            type="password"
            disabled={!inEditMode}
            id="newPassword"
            name="newPassword"
            required
            autoComplete="new-password"
            onChange={(event) => {
              setFieldChanged(true);

              handleChange(event.target.name, event.target.value);
            }}
            value={formFields.newPassword}
            label="Password"></Input>

          <Input
            type="password"
            disabled={!inEditMode}
            id="reenteredNewPassword"
            name="reenteredNewPassword"
            required
            autoComplete="new-password"
            onChange={(event) => {
              setFieldChanged(true);

              handleChange(event.target.name, event.target.value);
            }}
            value={formFields.reenteredNewPassword}
            label="Re-Enter Password"></Input>
        </div>
        <div className={inEditMode ? 'flex flex-col p-2 text-xs md:flex-row md:space-x-4' : 'hidden'}>
          <div className="flex w-full flex-col">
            <span
              className={
                formFields.newPassword.length > 0
                  ? formFields.newPassword.length >= 8
                    ? 'text-mint-medical-green'
                    : ' text-red-500'
                  : ' text-mint-medical-grey'
              }>
              Contains at least 8 characters
            </span>
            <span
              className={
                formFields.newPassword.length > 0
                  ? formFields.newPassword.match(/\d/g)
                    ? 'text-mint-medical-green'
                    : ' text-red-500'
                  : ' text-mint-medical-grey'
              }>
              Contains at least 1 number
            </span>
            <span
              className={
                formFields.newPassword.length > 0
                  ? formFields.newPassword.match(/[A-Z]/g)
                    ? 'text-mint-medical-green'
                    : ' text-red-500'
                  : ' text-mint-medical-grey'
              }>
              Contains at least 1 uppercase letter
            </span>
            <span
              className={
                formFields.newPassword.length > 0
                  ? formFields.newPassword.match(/[a-z]/g)
                    ? 'text-mint-medical-green'
                    : ' text-red-500'
                  : ' text-mint-medical-grey'
              }>
              Contains at least 1 lowercase letter
            </span>
          </div>
          <div className="flex w-full flex-col pl-2">
            <span
              className={
                formFields.newPassword.length > 0 && formFields.reenteredNewPassword.length > 0
                  ? formFields.newPassword === formFields.reenteredNewPassword
                    ? 'text-mint-medical-green'
                    : ' text-red-500'
                  : ' text-mint-medical-grey'
              }>
              Passwords must match
            </span>
          </div>
        </div>
      </form>
      <div className=" text-center text-red-500">{error}</div>
    </>
  );
}

import React, { useState } from 'react';
import BorderedContainer from '../../../../components/Containers/BorderedContainer';
import logo from '../../../../assets/mint_medical_logo.svg';
import { CompanyLicenseUsers } from '../CompanyLicenseUsers/CompanyLicenseUsers';
import { Toggle } from '../../../../components/Toggle/Toggle';
import { post } from '../../../../libs/Requests';
import { useProductData } from '../../../../hooks/useProductData';
import { ILicenseDTO } from '../../../../../../types/LicenseDTO';
import { FilledDownloader } from '../../../downloader/components/FilledDownloader';
import { BorderedDownloader } from '../../../downloader/components/BorderedDownloader';
import { Link } from 'react-router-dom';
import { getOrderTargetUrl } from '../../../order/routes';
import { useStripe } from '@stripe/react-stripe-js';

interface CompanyLicenseProps {
  subscription: ILicenseDTO;
  refreshLicensesCallback?: () => void;
}

export function CompanyLicense(props: CompanyLicenseProps) {
  const { productData, getTierName } = useProductData({
    productId: props.subscription.productId,
    lang: 'en'
  });

  const [subscription, setSubscription] = useState<ILicenseDTO>(props.subscription);
  const [licenseEditMode, setLicenseEditMode] = useState(false);
  const [autoRenewable, setAutoRenewable] = useState(!props.subscription.cancelAtPeriodEnd);
  const [licenseNumber, setLicenseNumber] = useState(0);
  const [numberOfLicenseUsers, setNumberOfLicenseUsers] = useState(0);
  const stripe = useStripe();

  async function handleEditClick() {
    try {
      if (licenseEditMode) {
        const subDiff = {
          cancel_at_period_end: !autoRenewable,
          licenses_number: licenseNumber
        };

        const sub = await post(`/company/subscription/${props.subscription.id}/`, JSON.stringify(subDiff));

        setSubscription(await sub.json());
      }

      setLicenseEditMode(!licenseEditMode);

      setLicenseNumber(subscription.seats);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleRetryPayment() {
    if (subscription.requiredAction && stripe) {
      await stripe.confirmCardPayment(subscription.requiredAction.paymentIntentSecret!);
    }

    props.refreshLicensesCallback && props.refreshLicensesCallback();
  }

  function handleToggle(checked: boolean) {
    setAutoRenewable(checked);
  }

  return (
    <>
      <div className="flex w-full flex-col gap-4 pb-4 md:w-1/2 md:flex-row">
        <FilledDownloader
          fileName={productData.binaryFileName!}
          label={`Download ${productData.name}`}
          versionLabel={productData.binaryFileVersion}></FilledDownloader>
        <BorderedDownloader
          fileName={productData.manualFileName!}
          label={'Download manual'}></BorderedDownloader>
      </div>

      <div className="mb-2 mt-2">
        <BorderedContainer key={props.subscription.id}>
          <div className="flex flex-col md:flex-row-reverse">
            <div className=" flex flex-col">
              <span className="font-semibold">License Number:</span>
              <span className="font-semibold">{props.subscription.id.replace('sub_', '')}</span>
            </div>

            <div className="flex flex-col gap-4 md:w-full md:flex-row">
              <div className="w-full md:w-1/6">
                <img
                  // className="w-full md:w-1/12"
                  src={logo}></img>
              </div>

              <div className="flex flex-col">
                <span className="font-semibold">
                  {props.subscription.type == 'OneTime' ? (
                    <>{productData?.name ? productData.name : ''}</>
                  ) : (
                    <>
                      {productData?.name ? productData.name : ''} |{' '}
                      {subscription.seats ? getTierName(subscription.seats) : ''}
                    </>
                  )}
                </span>
                <span>
                  {subscription.seats} Seat{subscription.seats > 1 ? 's' : ''}
                  {props.subscription.type === 'AutoRenewal' ? ', Billed annually' : ''}
                </span>
                <div className="flex flex-row space-x-1">
                  <span>Subscription status:</span>
                  <span
                    className={
                      subscription.status?.toLowerCase() === 'active' ? ' text-mint-medical-green' : ' text-red-500'
                    }>
                    {subscription.status.toUpperCase()}
                  </span>
                  {subscription.requiredAction && (
                    <span
                      className=" text-mint-medical-green hover:underline"
                      onClick={handleRetryPayment}>
                      {subscription.requiredAction.failureCode == 'authentication_required'
                        ? 'Authorize Payment'
                        : 'Retry Payment'}
                    </span>
                  )}
                </div>
                {subscription.type == 'OneTime' ? (
                  <span>
                    Creation Date: {new Date(subscription.licenseStartTimestamp! * 1000).toLocaleDateString()}
                  </span>
                ) : (
                  <span>
                    Purchase Date: {new Date(subscription.licenseStartTimestamp! * 1000).toLocaleDateString()}
                  </span>
                )}
                <div className="flex flex-col md:flex-row md:gap-x-2">
                  <span className="text-red-500">
                    {subscription.cancelAtTimestamp ? (
                      subscription.cancelAtTimestamp * 1000 > Date.now() ? (
                        <>Ends on {new Date(subscription.cancelAtTimestamp * 1000).toLocaleDateString()}</>
                      ) : (
                        <>Ended on {new Date(subscription.cancelAtTimestamp * 1000).toLocaleDateString()}</>
                      )
                    ) : subscription.status != 'canceled' ? (
                      <>
                        Auto renews on {new Date(subscription.currentPeriodEndTimestamp! * 1000).toLocaleDateString()}
                      </>
                    ) : subscription.canceledAtTimestamp ? (
                      <>Ended on {new Date(subscription.canceledAtTimestamp * 1000).toLocaleDateString()}</>
                    ) : (
                      ''
                    )}
                  </span>
                  {props.subscription.type == 'OneTime' && (
                    <Link to={getOrderTargetUrl(props.subscription.productId, props.subscription.seats)}>
                      Switch to Auto Renewable Subscription
                    </Link>
                  )}
                  {subscription.type === 'AutoRenewal' && (
                    <button
                      className={subscription.type === 'AutoRenewal' ? 'pl-4 text-mint-medical-green' : 'hidden'}
                      onClick={handleEditClick}>
                      {licenseEditMode ? 'Done' : 'Edit'}
                    </button>
                  )}
                  {licenseEditMode && (
                    <div className="flex flex-col py-2">
                      <Toggle
                        checked={!subscription.cancelAtPeriodEnd}
                        onChange={handleToggle}></Toggle>
                      <div className=" inline-flex pt-2">
                        <input
                          className="h-9 w-11 rounded-md border border-2 border-mint-medical-green bg-mint-medical-green-light text-center outline-none"
                          value={licenseNumber}
                          disabled={true}></input>

                        <div className="my-auto ms-3 text-sm font-medium text-gray-900">
                          <span>Seats</span>
                          <input
                            className="ms-3 h-9 w-9 cursor-pointer rounded-full border-2 border-mint-medical-green bg-mint-medical-green-lighter disabled:border"
                            type="button"
                            disabled={licenseNumber === subscription.seats}
                            onClick={() => {
                              if (licenseNumber - 1 >= subscription.seats) {
                                setLicenseNumber(licenseNumber - 1);
                              }
                            }}
                            value="-"></input>
                          <input
                            className="ms-3 h-9 w-9 cursor-pointer rounded-full border border-2 border-mint-medical-green bg-mint-medical-green-lighter"
                            type="button"
                            onClick={() => {
                              setLicenseNumber(licenseNumber + 1);
                            }}
                            value="+"></input>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <span className="italic">
                  {numberOfLicenseUsers}/{subscription.seats} users activated
                </span>
              </div>
            </div>
          </div>

          <div className="bg-red my-4 h-px w-full bg-mint-medical-grey-light"></div>

          <CompanyLicenseUsers
            isLicenseActive={props.subscription.status.toLocaleLowerCase() === 'active'}
            licenseId={props.subscription.id}
            onLicensedUsersNumberUpdated={(num) => {
              setNumberOfLicenseUsers(num);
            }}></CompanyLicenseUsers>
        </BorderedContainer>
      </div>
    </>
  );
}

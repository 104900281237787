import React, { useEffect, useState } from 'react';
import { Modal } from '../../../../components/Modal/Modal';
import { DataLoader, LoadingStatus } from '../../../../types/DataLoader';
import { get } from '../../../../libs/Requests';
import logo from '../../../../assets/mint_medical_logo.svg';
import { BorderedDownloader } from '../../../downloader/components/BorderedDownloader';
import { FullScreenSpinner } from '../../../../components/Spinners/FullScreenSpinner';
import { LocalizedPrice } from '../../../../components/Price/Price';

export interface InvoiceModalProps {
  onCloseClick: () => void;
  invoiceId: string;
}

interface InvoiceDTO {
  id: string;
  account_name: string;
  amount_due: number;
  amount_paid: number;
  currency: string;
  customer_address: {
    city: string;
    country: string;
    line1: string;
    line2: string;
    postal_code: string;
    state: string;
  };
  customer_email: string;
  customer_name: string;
  customer_phone: string;
  invoice_pdf: string;
  lines: {
    data: InvoiceLineDTO[];
  };
  number: string;
  status_transitions: {
    finalized_at: number;
    paid_at: number;
  };
  subtotal: number;
  tax: number;
  total: number;
}

interface InvoiceLineDTO {
  amount: number;
  amount_excluding_tax: string;
  currency: string;
  description: string;
  quantity: number;
  tax_amounts: InvoiceLineTaxDTO[];
  period: {
    start: number;
    end: number;
  };
}

interface InvoiceLineTaxDTO {
  amount: number;
  taxable_amount: number;
}

export interface InvoiceDataLoader extends DataLoader {
  data?: InvoiceDTO;
}

export function InvoiceModal(props: InvoiceModalProps) {
  const [dataLoader, setDataLoader] = useState<InvoiceDataLoader>({
    loadingStatus: LoadingStatus.Loading
  });

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setDataLoader({ loadingStatus: LoadingStatus.Loading });

    get(`/company/invoices/${props.invoiceId}`, { signal })
      .then(async (invoiceResponse) => {
        const invoiceData = await invoiceResponse.json();

        setDataLoader({
          ...dataLoader,
          data: invoiceData as InvoiceDTO,
          loadingStatus: LoadingStatus.Succeed
        });
      })
      .catch((error) => {
        setDataLoader({
          ...dataLoader,
          error: error.name === 'AbortError' ? undefined : 'Oops, some problems occurred during loading...',
          loadingStatus: LoadingStatus.Failed
        });
      });

    return () => {
      abortController.abort();
    };
  }, []);

  if (dataLoader.loadingStatus != LoadingStatus.Succeed) {
    return <FullScreenSpinner></FullScreenSpinner>;
  }

  return (
    <Modal
      closeModal={props.onCloseClick}
      visible={true}>
      <div className="h-full w-full space-y-8 p-8">
        <div>
          <div className="text-center">
            <div className="text-lg font-bold">Invoice</div>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row">
          <div className="flex flex-col">
            <table className="text-nowrap font-bold">
              <tr>
                <td>Invoice number</td>
                <td className="pl-4">{dataLoader.data?.number}</td>
              </tr>
              <tr>
                <td>Date of issue</td>
                <td className="pl-4">
                  {dataLoader.data?.amount_due
                    ? new Date(dataLoader.data?.status_transitions.paid_at * 1000).toLocaleDateString()
                    : ''}
                </td>
              </tr>
              <tr>
                <td>Date due</td>
                <td className="pl-4">
                  {dataLoader.data?.amount_due
                    ? new Date(dataLoader.data?.status_transitions.paid_at * 1000).toLocaleDateString()
                    : ''}
                </td>
              </tr>
            </table>
          </div>
          <div className="my-auto w-full items-center">
            <img
              className="w-full pl-12"
              src={logo}></img>
          </div>
        </div>
        {dataLoader.loadingStatus === LoadingStatus.Succeed && (
          <>
            <div className="flex w-full flex-row justify-between">
              <div className="font-bold">{dataLoader.data?.account_name}</div>
              <div className="flex flex-col">
                <span className="font-bold">Bill to</span>
                <span className="font-bold">{dataLoader.data?.customer_name}</span>
                <span>{dataLoader.data?.customer_address.line1}</span>
                <span>{dataLoader.data?.customer_address.line2}</span>
                <span>
                  {dataLoader.data?.customer_address.postal_code} {dataLoader.data?.customer_address.city}
                </span>
                <span>{dataLoader.data?.customer_address.state}</span>
                <span>
                  {dataLoader.data?.customer_address.country
                    ? new Intl.DisplayNames(undefined, { type: 'region' }).of(dataLoader.data?.customer_address.country)
                    : ''}
                </span>
                <span>{dataLoader.data?.customer_phone}</span>
                <span>{dataLoader.data?.customer_email}</span>
              </div>
            </div>

            <span className="font-bold">
              <LocalizedPrice
                currency={dataLoader.data!.currency}
                valueInCents={dataLoader.data!.total}></LocalizedPrice>{' '}
              due{' '}
              {dataLoader.data?.amount_due
                ? new Date(dataLoader.data?.status_transitions.paid_at * 1000).toLocaleDateString()
                : ''}
            </span>

            <table className="w-full">
              <thead className=" divide-y-2">
                <tr>
                  <th className="justify-start pr-4">Description</th>
                  <th className="px-4">Qty</th>
                  <th className="px-4">Unit Price</th>
                  <th className="px-4">Tax</th>
                  <th className="px-4">Amount</th>
                </tr>
                <tr className="h-px border-0 bg-mint-medical-grey-lighter"></tr>
              </thead>
              <tbody className="">
                {dataLoader.data?.lines.data
                  .filter((itm) => itm.quantity > 0)
                  .map((line) => (
                    <tr
                      key={line.description}
                      className=" h-12">
                      <td className="justify-start">
                        <p>{line.description.split('(').at(0)}</p>
                        <p className=" break-before-auto text-xs">
                          {new Date(line.period.start * 1000).toLocaleDateString()} -{' '}
                          {new Date(line.period.end * 1000).toLocaleDateString()}
                        </p>
                      </td>
                      <td className="flex justify-end">
                        <p>{line.quantity}</p>
                      </td>
                      <td className=" justify-center"></td>
                      <td className=" justify-center"></td>
                      <td className="flex justify-end">
                        <p>
                          <LocalizedPrice
                            valueInCents={line.amount}
                            currency={dataLoader.data!.currency}></LocalizedPrice>
                        </p>
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td colSpan={3}></td>
                  <td
                    colSpan={2}
                    className="h-px border-0 bg-mint-medical-grey-lighter"></td>
                </tr>
                <tr>
                  <td colSpan={3}></td>
                  <td className="justify-end text-right font-semibold">Subtotal</td>
                  <td className="flex justify-end">
                    <LocalizedPrice
                      valueInCents={dataLoader.data!.subtotal}
                      currency={dataLoader.data!.currency}></LocalizedPrice>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}></td>
                  <td
                    colSpan={2}
                    className="h-px border-0 bg-mint-medical-grey-lighter"></td>
                </tr>
                <tr>
                  <td colSpan={3}></td>
                  <td className="justify-end text-right font-semibold">Tax</td>
                  <td className="flex justify-end">
                    <LocalizedPrice
                      valueInCents={dataLoader.data!.tax}
                      currency={dataLoader.data!.currency}></LocalizedPrice>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}></td>
                  <td
                    colSpan={2}
                    className="h-px border-0 bg-mint-medical-grey-lighter"></td>
                </tr>
                <tr>
                  <td colSpan={3}></td>
                  <td className="justify-end text-right font-semibold">Total</td>
                  <td className="flex justify-end">
                    <LocalizedPrice
                      valueInCents={dataLoader.data!.total}
                      currency={dataLoader.data!.currency}></LocalizedPrice>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="flex w-full flex-row">
              <div className="w-1/3">
                <BorderedDownloader
                  fileName={dataLoader.data!.invoice_pdf}
                  label={'Download PDF'}></BorderedDownloader>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

import React from 'react';
import { RouteObject } from 'react-router-dom';
import { MainLayout } from '../../../layout/MainLayout';
import { ResetPasswordPage } from '../pages/ResetPassword/ResetPassword';
import { ResetPasswordCodeConfirmPage } from '../pages/ResetPasswordCodeConfirm/ResetPasswordCodeConfirm';
import { SignInPage } from '../pages/SignIn/SignIn';
import { SignInCodeConfirmPage } from '../pages/SignInCodeConfirm/SignInCodeConfirm';
import { SignInWithNewPasswordRequiredPage } from '../pages/SignInWithNewPasswordRequired/SignInWithNewPasswordRequired';
import { LogoutPage } from '../pages/Logout/Logout';

const authRoutes: RouteObject[] = [
  {
    path: '/',
    element: <MainLayout></MainLayout>,
    children: [
      {
        path: 'auth/reset_password',
        element: <ResetPasswordPage></ResetPasswordPage>
      },
      {
        path: 'auth/reset_password_code_confirm',
        element: <ResetPasswordCodeConfirmPage></ResetPasswordCodeConfirmPage>
      },
      {
        path: 'auth/sign_in',
        element: <SignInPage></SignInPage>
      },
      {
        path: 'auth/logout',
        element: <LogoutPage></LogoutPage>
      },
      {
        path: 'auth/sign_in_with_new_password',
        element: <SignInWithNewPasswordRequiredPage></SignInWithNewPasswordRequiredPage>
      },
      {
        path: 'auth/sign_in_code_confirm',
        element: <SignInCodeConfirmPage></SignInCodeConfirmPage>
      }
    ]
  }
];

export default authRoutes;

import React from 'react';

interface CompanyLicenseUserProps {
  user: {
    email: string;
    firstName: string;
    lastName: string;
    userStatus: string;
  };
  onDeleteClicked: (userEmail: string) => void;
  onResendClicked: (userEmail: string) => void;
}

export function CompanyLicenseUser(props: CompanyLicenseUserProps) {
  function handleLicenseUserRemoveClick() {
    props.onDeleteClicked(props.user.email);
  }

  function handleLicenseUserResendClick() {
    props.onResendClicked(props.user.email);
  }

  return (
    <>
      <tr
        key={props.user.email}
        className="flex justify-between py-1">
        <td className="flex flex-row">
          <div className="my-auto pr-4">
            {props.user.userStatus === 'CONFIRMED' ? (
              <div className="my-auto flex h-14 w-14 rounded-full bg-mint-medical-green-lighter">
                <div className="mx-auto my-auto text-center font-semibold text-mint-medical-green">
                  {props.user.firstName ? <>{props.user.firstName.toString().charAt(0).toUpperCase()}</> : null}
                  {props.user.lastName ? <>{props.user.lastName.toString().charAt(0).toUpperCase()}</> : null}
                </div>
              </div>
            ) : (
              <div className="my-auto flex h-14 w-14 rounded-full bg-white"></div>
            )}
          </div>
          <div>
            <div>
              {props.user.userStatus === 'CONFIRMED' ? (
                <>
                  {props.user.lastName}, {props.user.firstName}
                </>
              ) : (
                <span className=" italic">Invite sent</span>
              )}
            </div>
            <div>{props.user.email}</div>
          </div>
        </td>
        <td className="flex">
          {props.user.userStatus === 'CONFIRMED' && (
            <button
              className=" my-auto font-bold text-red-500 hover:underline"
              onClick={handleLicenseUserRemoveClick}>
              Remove
            </button>
          )}
          {props.user.userStatus !== 'CONFIRMED' && (
            <>
              <button
                className=" my-auto pr-4 font-bold text-mint-medical-green hover:underline"
                onClick={handleLicenseUserResendClick}>
                Resend Invite
              </button>
              <button
                className=" my-auto font-bold text-red-500 hover:underline"
                onClick={handleLicenseUserRemoveClick}>
                Cancel Invite
              </button>
            </>
          )}
        </td>
      </tr>
    </>
  );
}

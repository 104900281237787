import React, { useState } from 'react';
import { get } from '../../../../libs/Requests';
import XLSX from 'xlsx';
import { FullScreenSpinner } from '../../../../components/Spinners/FullScreenSpinner';

interface OrganizationLicense {
  licenseType: string;
  licenseSeats: string;
  licenseStartPeriod: string;
  licenseEndPeriod: string;
}

interface OrganizationMember {
  accountCreationDate: string;
  email: string;
  fullName: string;
  license?: string;
  phone?: string;
  position?: string;
}

interface OrganizationXLSXReportDataItem {
  companyName: string;
  companyManagerEmail: string;
  companyManagerFullName: string;
  companyManagerPhone?: string;
  companyAccountCreationDate: string;
  companyLicenses: OrganizationLicense[];
  companyMembers: OrganizationMember[];
}

interface XLSXDataItem {
  'Organization Name': string;
  Role: string;
  'Full Name': string;
  Email: string;
  'Creation Date'?: string;
  Phone?: string;
}

export function OrganizationXLSXReport() {
  const [spinner, setSpinner] = useState(false);
  const [reportStatusType] = useState('all');
  const [reportPeriod] = useState('all');

  async function handleDownloadXLSXOrganizationReport() {
    setSpinner(true);

    try {
      const reportData = await get(
        `/admin/xlsx_organization_report_data?status=${reportStatusType}&period=${reportPeriod}`
      );

      const reportDataItems: OrganizationXLSXReportDataItem[] = await reportData.json();

      const processedItems: XLSXDataItem[] = [];

      reportDataItems.forEach((reportDataItem) => {
        // process organization manager
        processedItems.push({
          'Organization Name': reportDataItem.companyName,
          Role: 'Manager',
          'Full Name': reportDataItem.companyManagerFullName,
          Email: reportDataItem.companyManagerEmail,
          Phone: reportDataItem.companyManagerPhone,
          'Creation Date': reportDataItem.companyAccountCreationDate
        });

        // process organization member
        reportDataItem.companyMembers.forEach((companyMember) => {
          processedItems.push({
            'Organization Name': reportDataItem.companyName,
            Role: 'Member',
            'Full Name': companyMember.fullName,
            Email: companyMember.email,
            Phone: companyMember.phone,
            'Creation Date': companyMember.accountCreationDate
          });
        });
      });

      const worksheet = XLSX.utils.json_to_sheet(processedItems);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Organizations');

      /* calculate column width */
      const max_width = reportDataItems.reduce(
        (w, r) =>
          Math.max(
            w,
            r.companyAccountCreationDate.length,
            r.companyLicenses.length,
            r.companyManagerEmail.length,
            r.companyName.length,
            'Manager'.length
          ),
        20
      );
      worksheet['!cols'] = [
        { wch: max_width },
        { wch: max_width },
        { wch: max_width },
        { wch: max_width },
        { wch: max_width },
        { wch: max_width }
      ];

      /* create an XLSX file and try to save to Presidents.xlsx */
      XLSX.writeFile(workbook, 'report.xlsx');
    } catch (error) {
      console.error(error);
    }
    setSpinner(false);
  }

  return (
    <>
      {spinner && <FullScreenSpinner></FullScreenSpinner>}
      <div
        className="mx-auto flex cursor-pointer flex-row text-mint-medical-green hover:underline"
        onClick={handleDownloadXLSXOrganizationReport}>
        <svg
          className=" my-auto h-8 w-8 fill-mint-medical-green p-1"
          viewBox="0 0 20 20">
          <path d="M8 0V9H4L10 15L16 9H12V0H8ZM0 18V20H20V18H0Z" />
        </svg>
        <span className="my-auto text-mint-medical-green">Download XLSX Organizations Report</span>
      </div>
    </>
  );
}

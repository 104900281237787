let _language: string;
let _country: string;
let _lang: string;

export function useLanguage() {
  if (!_language) {
    _language = window.navigator.language;
  }

  if (!_lang) {
    _lang = _language.split('-').at(0)!;
  }

  if (!_country) {
    _country = _language.split('-').pop()!.toLowerCase();
  }

  return { country: _country, lang: _lang };
}

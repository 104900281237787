import React, { useState } from 'react';
import { Input } from '../../../../components/Input';
import { Header, HeaderWrapper } from '../../../../components/Header';
import { confirmResetPassword } from '@aws-amplify/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { FullScreenMessage, FullScreenSpinner } from '../../../../components/Spinners/FullScreenSpinner';

export interface NewPasswordsDTO {
  email: string;
  code: string;
  password: string;
  reenteredPassword: string;
}

export function ResetPasswordCodeConfirmPage() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isDuringSubmission, setIsDuringSubmission] = useState<boolean>(false);
  const [showSuccessfulMessage, setShowSuccessfulMessage] = useState<boolean>(false);

  const loc = useLocation();
  const searchParams = new URLSearchParams(loc.search);

  const [newPasswordsFormFields, setNewPasswordsFormFields] = useState<NewPasswordsDTO>({
    email: '',
    password: '',
    code: '',
    reenteredPassword: ''
  });

  function handlePasswordsFormChange(name: string, value: string) {
    setNewPasswordsFormFields({ ...newPasswordsFormFields, [name]: value });
  }

  async function handleSetNewPasswordFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsDuringSubmission(true);

    if (newPasswordsFormFields.password !== newPasswordsFormFields.reenteredPassword) {
      setError('Passwords not match!');

      setIsDuringSubmission(false);
    }

    try {
      await confirmResetPassword({
        username: searchParams.get('email_address')!,
        confirmationCode: newPasswordsFormFields.code,
        newPassword: newPasswordsFormFields.password
      });

      setShowSuccessfulMessage(true);

      setTimeout(() => {
        setShowSuccessfulMessage(false);

        if (searchParams.get('returnUrl')) {
          navigate(searchParams.get('returnUrl')!);
        } else {
          navigate('/auth/sign_in');
        }
      }, 2000);
    } catch (error) {
      const tmp = `${error}`.split('ion: ').pop();

      console.error(tmp);

      setError(tmp as string);
    }

    setIsDuringSubmission(false);
  }

  return (
    <>
      <HeaderWrapper>
        <Header>Set new password</Header>
      </HeaderWrapper>
      <div className="mx-auto w-full bg-white md:w-2/6">
        {isDuringSubmission && <FullScreenSpinner></FullScreenSpinner>}
        {showSuccessfulMessage && (
          <FullScreenMessage message={'The new password has been successfully set.'}></FullScreenMessage>
        )}

        <form
          id="signInForm"
          onSubmit={handleSetNewPasswordFormSubmit}>
          <Input
            autoComplete="off"
            id="code"
            type="text"
            value={newPasswordsFormFields.code}
            onChange={(event) => {
              handlePasswordsFormChange('code', event.target.value);
            }}
            required={true}
            label="Code"></Input>
          <Input
            autoComplete="new-password"
            id="password"
            type="password"
            value={newPasswordsFormFields.password}
            onChange={(event) => {
              handlePasswordsFormChange('password', event.target.value);
            }}
            required={true}
            label="New Password"></Input>
          <div className="flex w-full flex-col p-2 text-xs">
            <span
              className={
                newPasswordsFormFields.password.length > 0
                  ? newPasswordsFormFields.password.length >= 8
                    ? 'text-mint-medical-green'
                    : ' text-red-500'
                  : ' text-mint-medical-grey'
              }>
              Contains at least 8 characters
            </span>
            <span
              className={
                newPasswordsFormFields.password.length > 0
                  ? newPasswordsFormFields.password.match(/\d/g)
                    ? 'text-mint-medical-green'
                    : ' text-red-500'
                  : ' text-mint-medical-grey'
              }>
              Contains at least 1 number
            </span>
            <span
              className={
                newPasswordsFormFields.password.length > 0
                  ? newPasswordsFormFields.password.match(/[A-Z]/g)
                    ? 'text-mint-medical-green'
                    : ' text-red-500'
                  : ' text-mint-medical-grey'
              }>
              Contains at least 1 uppercase letter
            </span>
            <span
              className={
                newPasswordsFormFields.password.length > 0
                  ? newPasswordsFormFields.password.match(/[a-z]/g)
                    ? 'text-mint-medical-green'
                    : ' text-red-500'
                  : ' text-mint-medical-grey'
              }>
              Contains at least 1 lowercase letter
            </span>
          </div>
          <Input
            id="reenteredPassword"
            label="Re-Enter Password"
            onChange={(event) => {
              handlePasswordsFormChange('reenteredPassword', event.target.value);
            }}
            required
            autoComplete="new-password"
            type="password"></Input>
          <div className="flex w-full flex-col p-2 text-xs">
            <span
              className={
                newPasswordsFormFields.password.length > 0 && newPasswordsFormFields.reenteredPassword.length > 0
                  ? newPasswordsFormFields.password === newPasswordsFormFields.reenteredPassword
                    ? 'text-mint-medical-green'
                    : ' text-red-500'
                  : ' text-mint-medical-grey'
              }>
              Passwords must match
            </span>
          </div>

          <div className="flex min-h-8 w-full">
            <span className={error ? 'visible mx-auto self-center text-center text-red-500' : ' invisible'}>
              {error as string}
            </span>
          </div>

          <div className="">
            <Input
              disabled={isDuringSubmission}
              type="submit"
              value="Update password"></Input>
          </div>
        </form>
      </div>
    </>
  );
}

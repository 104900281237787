import React, { useState } from 'react';
import { useProductData } from '../../../../hooks/useProductData';
import BorderedContainer from '../../../../components/Containers/BorderedContainer';
import { ILicenseDTO } from '../../../../../../types/LicenseDTO';
import { IUpdateLicenseDTO } from '../../../../../../types/UpdateLicenseDTO';
import logo from '../../../../assets/mint_medical_logo.svg';
import { put } from '../../../../libs/Requests';
import { Input, TextArea } from '../../../../components/Input';
import { InViewSpinner } from '../../../../components/Spinners/InViewSpinner';

export interface OrganizationLicenseProps {
  license: ILicenseDTO;
  isMintAdmin?: boolean;
  companyId?: string;
  onLicenseEdited?: () => void;
}

export function OrganizationLicense(props: OrganizationLicenseProps) {
  const [duringRequest, setDuringRequest] = useState(false);
  const [licenseEditMode, setLicenseEditMode] = useState(false);
  const [newLicenseNumber, setNewLicenseNumber] = useState<number>(parseInt(props.license.seats.toString()));
  const [newExpirationDate, setNewExpirationDate] = useState<string>(
    new Date(props.license.currentPeriodEndTimestamp! * 1000).toISOString().slice(0, 10)
  );
  const [newKeywords, setNewKeywords] = useState(props.license.keywords);
  const { productData, getTierName, getTierPrice, calculateYearlyPriceForGivenSeats } = useProductData({
    productId: props.license.productId,
    lang: 'en'
  });

  function isSomethingChanged(): boolean {
    return props.license.type == 'OneTime'
      ? newLicenseNumber != props.license.seats ||
          new Date(`${newExpirationDate}T23:59:59`).getTime() / 1000 != props.license.currentPeriodEndTimestamp ||
          newKeywords != props.license.keywords
      : newKeywords != props.license.keywords;
  }

  function handleSeatsIncreaseClick() {
    setNewLicenseNumber(newLicenseNumber + 1);
  }

  function handleSeatsDecreaseClick() {
    if (newLicenseNumber - 1 >= props.license.seats) {
      setNewLicenseNumber(newLicenseNumber - 1);
    }
  }

  function handleKeywordsChange(newKeywordsValue: string) {
    if (newKeywordsValue != newKeywords) {
      if (!newKeywordsValue.length) {
        setNewKeywords('');

        return;
      }

      if (/^([a-zA-Z0-9]+)?(;[a-zA-Z0-9]*)*$/gim.test(newKeywordsValue)) {
        setNewKeywords(newKeywordsValue);
      }
    }
  }

  function handleExpirationTimestampChange(newExpirationDate: string) {
    const newExpirationTimestamp = new Date(`${newExpirationDate}T23:59:59`).getTime();

    setNewExpirationDate(new Date(newExpirationTimestamp).toISOString().slice(0, 10));
  }

  async function handleEditClick() {
    try {
      if (licenseEditMode) {
        if (isSomethingChanged()) {
          setDuringRequest(true);

          const updateData: IUpdateLicenseDTO = {
            companyId: props.companyId!,
            productId: props.license.productId,
            licenseId: props.license.id,
            keywords: newKeywords,
            seats: newLicenseNumber,
            expirationTimestamp: new Date(`${newExpirationDate}T23:59:59`).getTime()
          };

          await put(`/admin/company/${props.companyId}/licenses`, JSON.stringify(updateData));

          setDuringRequest(false);
          props.onLicenseEdited && props.onLicenseEdited();
        }
      }

      setLicenseEditMode(!licenseEditMode);
    } catch (error) {
      console.error(error);
    }
  }

  if (duringRequest) {
    return <InViewSpinner></InViewSpinner>;
  }

  return (
    <div className="mb-2 mt-2">
      <BorderedContainer>
        <div>
          {props.isMintAdmin && (
            <div className="relative">
              <button
                className={'absolute right-0 pl-4 font-bold text-mint-medical-green'}
                onClick={handleEditClick}>
                {licenseEditMode ? (isSomethingChanged() ? 'Save' : 'Cancel') : 'Edit'}
              </button>
            </div>
          )}
          <div className="flex flex-col justify-between self-start md:flex-row">
            <img
              className="mr-2 w-3/4 self-center py-4 md:w-1/6 md:self-start"
              src={logo}></img>
            <div className={'my-auto flex w-full flex-row justify-between self-start'}>
              <span className="pl-0 text-base font-semibold text-mint-medical-grey md:pl-4">
                {props.license.type == 'OneTime' ? (
                  <>{productData?.name ? productData.name : ''}</>
                ) : (
                  <>
                    {productData?.name ? productData.name : ''} |{' '}
                    {props.license.seats ? getTierName(props.license.seats) : ''}
                  </>
                )}
              </span>
              <span className="text-base font-semibold">
                {props.license.type == 'OneTime' ? (
                  <></>
                ) : (
                  <>
                    <>{props.license.currency == 'usd' ? '$' : props.license.currency == 'eur' ? '€' : ''}</>
                    {getTierPrice(props.license.seats)} per user/month
                  </>
                )}
              </span>
            </div>
          </div>
          {(props.license.type == 'AutoRenewal' || !licenseEditMode) && (
            <div className="flex flex-row justify-between self-start">
              <span className=" my-auto flex flex-col text-wrap pr-1 text-base font-semibold text-mint-medical-grey md:flex-row">
                Number of users{!props.isMintAdmin && ' for your plan'}
              </span>
              <input
                id="seats"
                disabled={true}
                className="w-12 rounded-md border-2 border-mint-medical-green-lighter p-2 text-center focus:border-mint-medical-green focus:outline-none md:w-24"
                value={props.license.seats}
                type="number"></input>
            </div>
          )}
          {props.license.type != 'OneTime' ? (
            <div className="">
              <div className="flex justify-between">
                <span className="my-auto text-base font-semibold text-mint-medical-grey">Total</span>
                <span className="text-base font-semibold">
                  {calculateYearlyPriceForGivenSeats(props.license.seats)} billed annually
                </span>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="flex flex-row space-x-1">
            <span>Subscription status:</span>
            <span
              className={
                props.license.status?.toLowerCase() === 'active' ? ' text-mint-medical-green' : ' text-red-500'
              }>
              {props.license.status.toUpperCase()}
            </span>
          </div>

          {props.license.type == 'OneTime' ? (
            <span>Creation Date: {new Date(props.license.licenseStartTimestamp! * 1000).toLocaleDateString()}</span>
          ) : (
            <span>Purchase Date: {new Date(props.license.licenseStartTimestamp! * 1000).toLocaleDateString()}</span>
          )}

          <div>
            {(props.license.type == 'AutoRenewal' || !licenseEditMode) && (
              <span className="text-[#D81818]">
                {props.license.cancelAtTimestamp ? (
                  props.license.cancelAtTimestamp * 1000 > Date.now() ? (
                    <>Ends on {new Date(props.license.cancelAtTimestamp * 1000).toLocaleDateString()}</>
                  ) : (
                    <>Ended on {new Date(props.license.cancelAtTimestamp * 1000).toLocaleDateString()}</>
                  )
                ) : props.license.status != 'canceled' ? (
                  <>Auto renews on {new Date(props.license.currentPeriodEndTimestamp! * 1000).toLocaleDateString()}</>
                ) : props.license.canceledAtTimestamp ? (
                  <>Ended on {new Date(props.license.canceledAtTimestamp * 1000).toLocaleDateString()}</>
                ) : (
                  ''
                )}
              </span>
            )}

            {licenseEditMode && props.license.type == 'OneTime' && (
              <>
                <span>Expiration Date:</span>
                <div className="w-full md:w-1/4">
                  <Input
                    id="expirationDate"
                    type="date"
                    min={new Date().toISOString().slice(0, 10)}
                    value={newExpirationDate}
                    onChange={(event) => {
                      handleExpirationTimestampChange(event.target.value);
                    }}
                    required></Input>
                </div>

                <div className="flex flex-col py-2">
                  <span>Seats number:</span>
                  <div className=" inline-flex pt-2">
                    <input
                      className="h-9 w-11 rounded-md border border-2 border-mint-medical-green bg-mint-medical-green-light text-center outline-none"
                      value={newLicenseNumber}
                      disabled={true}></input>

                    <div className="my-auto ms-3 text-sm font-medium text-gray-900">
                      <span>Seats</span>
                      <input
                        className="ms-3 h-9 w-9 cursor-pointer rounded-full border-2 border-mint-medical-green bg-mint-medical-green-lighter disabled:border"
                        type="button"
                        disabled={newLicenseNumber === props.license.seats}
                        onClick={() => {
                          handleSeatsDecreaseClick();
                        }}
                        value="-"></input>
                      <input
                        className="ms-3 h-9 w-9 cursor-pointer rounded-full border border-2 border-mint-medical-green bg-mint-medical-green-lighter"
                        type="button"
                        onClick={() => {
                          handleSeatsIncreaseClick();
                        }}
                        value="+"></input>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="space-y-2 py-2">
              <span>
                Enter keywords separated by semicolon. For keywords use letters and numbers only. The maximum length is
                limited to 256 characters.
              </span>
              <TextArea
                disabled={!licenseEditMode}
                maxlength={256}
                value={newKeywords}
                onChange={(event) => {
                  handleKeywordsChange(event.target.value);
                }}
                placeholder='Example: "recist;pcwg3"'></TextArea>
            </div>
          </div>
        </div>

        <div className="text-md py-4 text-justify font-semibold text-mint-medical-grey">{productData.description}</div>
      </BorderedContainer>
    </div>
  );
}

import { fetchAuthSession } from '@aws-amplify/auth';
import { AppConfig } from '../config/Config';

async function getAccessToken() {
  return (await fetchAuthSession()).tokens?.accessToken;
}

async function getApiUrl() {
  return await (
    await AppConfig.getInstance().getConfig()
  ).apiUrl!;
}

async function get(url: string, props?: RequestInit) {
  return await _request(url, 'GET', undefined, props);
}

async function post(url: string, data: string): Promise<Response> {
  return await _request(url, 'POST', data);
}

async function put(url: string, data: string) {
  return await _request(url, 'PUT', data);
}

async function deleteRequest(url: string, data?: string) {
  return await _request(url, 'DELETE', data);
}

interface StoreRequestInit {
  method: string;
  body?: string | undefined;
  headers?:
    | {
        Authorization?: string | undefined;
      }
    | undefined;
}

async function _request(path: string, method?: string, body?: string, props?: RequestInit): Promise<Response> {
  const absoluteUrl = path.startsWith('http') ? path : (await getApiUrl()) + path;
  const token = await getAccessToken();

  const requestProps: StoreRequestInit = {
    method: method ?? 'GET',
    body: body,
    headers: {}
  };

  if (token) {
    requestProps!.headers!.Authorization = `${token}`;
  }

  const finalRequestParams: RequestInit = {
    credentials: 'include',
    mode: 'cors',
    ...props,
    ...requestProps
  };

  try {
    const fetchResult = await fetch(absoluteUrl, finalRequestParams);

    if (fetchResult?.status === 401) {
      console.warn(401, 'Redirection to sign_in');
      window.location.pathname = '/auth/logout';
    }

    return fetchResult;
  } catch (error) {
    // console.error(error);

    return Promise.reject();
  }
}

export { get, post, put, deleteRequest };

import React, { useEffect, useState } from 'react';
import { DataLoader, LoadingStatus } from '../../../../types/DataLoader';
import { InViewSpinner } from '../../../../components/Spinners/InViewSpinner';
import { get } from '../../../../libs/Requests';
import { AddOrganizationLicenseForm } from '../AddOrganizationLicenseForm/AddOrganizationLicenseForm';
import { OrganizationLicense } from '../OrganizationLicense/OrganizationLicense';
import { ILicenseDTO } from '../../../../../../types/LicenseDTO';

interface OrganizationLicensesDTO {
  licenses: ILicenseDTO[];
}

export interface OrganizationLicensesDataLoader extends DataLoader {
  data?: OrganizationLicensesDTO;
}

export interface OrganizationLicensesProps {
  companyId: string;
  editable: boolean;
}

export function OrganizationLicenses(props: OrganizationLicensesProps) {
  const [addDisabled, setAddDisabled] = useState(false);
  const [addOrgLicenseOnScreen, setAddOrgLicenseOnScreen] = useState(false);
  const [fetchData, setFetchData] = useState(true);
  const [companyDataLoader, setCompanyDataLoader] = useState<OrganizationLicensesDataLoader>({
    loadingStatus: LoadingStatus.Loading,
    data: {
      licenses: []
    }
  });

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setCompanyDataLoader({
      ...companyDataLoader,
      loadingStatus: LoadingStatus.Loading
    });

    get(`/admin/company/${props.companyId}/`, { signal })
      .then(async (companyResponse) => {
        const data = (await companyResponse.json()) as OrganizationLicensesDTO;

        const atLeastOneNonCanceledLicense = !!data.licenses.filter(
          (license) => license.status != 'incomplete_expired' && license.status != 'canceled'
        ).length;
        setAddDisabled(atLeastOneNonCanceledLicense);

        setCompanyDataLoader({
          ...companyDataLoader,
          data: data as OrganizationLicensesDTO,
          loadingStatus: LoadingStatus.Succeed
        });
      })
      .catch((error) => {
        setCompanyDataLoader({
          ...companyDataLoader,
          error: error.name === 'AbortError' ? undefined : 'Oops, some problems occurred during loading...',
          loadingStatus: LoadingStatus.Failed
        });
      });

    setFetchData(false);

    return () => {
      abortController.abort();
    };
  }, [fetchData]);

  return (
    <>
      {addOrgLicenseOnScreen && (
        <AddOrganizationLicenseForm
          onAddOrganizationLicenseSuccessful={() => {
            setAddOrgLicenseOnScreen(false);
            setFetchData(true);
          }}
          onClose={() => {
            setAddOrgLicenseOnScreen(false);
          }}
          companyId={props.companyId}></AddOrganizationLicenseForm>
      )}
      <div className="flex justify-between py-4">
        <span className="font-semibold">Licenses</span>
        <button
          className="font-semibold text-mint-medical-green hover:underline disabled:text-mint-medical-grey"
          disabled={!props.editable || addDisabled}
          onClick={() => {
            setAddOrgLicenseOnScreen(true);
          }}>
          Add
        </button>
      </div>

      {companyDataLoader.loadingStatus !== LoadingStatus.Succeed ? (
        <>{companyDataLoader.error || <InViewSpinner></InViewSpinner>}</>
      ) : null}

      {companyDataLoader.loadingStatus === LoadingStatus.Succeed && (
        <div>
          {!companyDataLoader.data?.licenses.length ? <div>There is no licenses for that organization.</div> : null}
          {companyDataLoader.data?.licenses.map((lic) => (
            <>
              <OrganizationLicense
                companyId={props.companyId}
                onLicenseEdited={() => {
                  setFetchData(true);
                }}
                license={lic}
                isMintAdmin={true}
                key={lic.id}></OrganizationLicense>
            </>
          ))}
        </div>
      )}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { Amplify, ResourcesConfig } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import AppRoutes from './routes';
import { AppConfig } from './config/Config';
import { AppConfigProvider } from './config/AppConfigProvider';
import { FullScreenSpinner } from './components/Spinners/FullScreenSpinner';

import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';

let stripePromise: Promise<Stripe | null>;

async function initAmplify() {
  const cfg = (await AppConfig.getInstance().getConfig()).cognitoConfiguration;

  if (location.search) {
    const params1 = new URLSearchParams(location.search);

    if (params1.get('client_id')) {
      cfg.aws_user_pools_web_client_id = params1.get('client_id');
    }
  }

  const cognitoConfig = cfg as ResourcesConfig;

  Amplify.configure(cognitoConfig);

  cognitoUserPoolsTokenProvider.setKeyValueStorage(
    new CookieStorage({ domain: `.${location.host}`, secure: true, sameSite: 'lax' })
  );
}

async function initStripe() {
  stripePromise = loadStripe(await (await AppConfig.getInstance().getConfig())!.stripeConfiguration!.publishable_key!);
}

async function initDependencies() {
  await initAmplify();

  await initStripe();
}

function App() {
  const [initializingDependencies, setInitializingDependencies] = useState(true);

  useEffect(() => {
    initDependencies().finally(() => {
      setInitializingDependencies(false);
    });
  }, []);

  if (initializingDependencies) return <FullScreenSpinner></FullScreenSpinner>;

  return (
    <>
      <AppConfigProvider>
        <Authenticator.Provider>
          <Elements stripe={stripePromise}>
            <HelmetProvider>
              <AppRoutes></AppRoutes>
            </HelmetProvider>
          </Elements>
        </Authenticator.Provider>
      </AppConfigProvider>
    </>
  );
}

export default App;

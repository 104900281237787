import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { HeadMetadata } from '../../../../components/HeadMetadata';
import { Header, HeaderWrapper } from '../../../../components/Header';
import { SignInDTO, SignInForm } from '../../components/SignInForm';
import { fetchAuthSession, signIn, SignInInput } from '@aws-amplify/auth';
import { FullScreenSpinner } from '../../../../components/Spinners/FullScreenSpinner';
import { signInOutputHandler } from '../../utils/signInOutputHandler';
import { useUserAgent } from '../../../../hooks/useUserAgent';

import i18next from '../../../../i18n';

export function SignInPage() {
  const { userAgent } = useUserAgent();
  const [fullScreenSpinnerOnScreen, setFullScreenSpinnerOnScreen] = useState(false);
  const [formError, setFormError] = useState<unknown>('');
  const location = useLocation();
  const navigator = useNavigate();
  const [userLoggedIn] = useState(false);
  const { user } = useAuthenticator((context) => {
    return [context.user];
  });

  async function handleLogIn(formData: SignInDTO) {
    setFullScreenSpinnerOnScreen(true);
    setFormError('');

    const password = formData.password;
    const email = formData.email;

    try {
      const signInInput: SignInInput = {
        username: email,
        password: password,
        options: {
          authFlowType: 'CUSTOM_WITH_SRP',
          clientMetadata: {
            userAgent
          }
        }
      };

      const signInOutput = await signIn(signInInput);

      setFullScreenSpinnerOnScreen(false);

      signInOutputHandler(
        signInOutput,
        navigator,
        {
          username: email,
          password: password
        },
        {
          location: location.state?.location,
          search: location.search
        }
      );
    } catch (error) {
      setFullScreenSpinnerOnScreen(false);
      showFormError(error);
    }
  }

  function showFormError(error: unknown) {
    const errorText = `${error}`.split('tion: ').pop();

    setFormError(errorText);
  }

  async function handleLoggedInUser() {
    const searchParams = new URLSearchParams(location.state?.search);
    const redirectUrl = searchParams.get('redirect_uri');

    const { idToken, accessToken } = (await fetchAuthSession()).tokens!;

    if (redirectUrl) {
      if (redirectUrl?.startsWith('http')) {
        let _ = '#id_token=';
        _ += idToken?.toString();
        _ += '&access_token=';
        _ += accessToken?.toString();
        const d = accessToken.payload['exp']! - Date.now() / 1000;
        _ += '&token_type=bearer&expires_in=' + parseInt(d.toString());
        _ += '&state=' + searchParams.get('state');

        console.log('Redirection from sign in:');
        console.log(redirectUrl + _);

        window.location.href = redirectUrl + _;
      }
    }
  }

  if (userLoggedIn || user) {
    handleLoggedInUser();
    return <></>;
  }

  if (userLoggedIn || user) {
    return (
      <Navigate
        to={location.state ? location.state.location : '/account'}
        replace={true}
      />
    );
  }

  return (
    <>
      <HeadMetadata title={'Mint Medical Subscription Store | Sign In'}></HeadMetadata>

      <HeaderWrapper>
        <Header>{i18next.t('sign_in.title')}</Header>
      </HeaderWrapper>

      <div className="flex max-h-fit w-full max-w-screen-xl flex-col space-y-4 bg-white py-4 md:py-8">
        <div className="text-xl text-[#282829] md:text-center md:text-2xl">{i18next.t('sign_in.description')}</div>
      </div>

      {fullScreenSpinnerOnScreen && <FullScreenSpinner></FullScreenSpinner>}

      <div className="mx-auto w-full bg-white md:w-2/6">
        <SignInForm
          onSubmit={handleLogIn}
          submissionError={formError}></SignInForm>
      </div>
    </>
  );
}

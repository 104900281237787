import React, { useEffect, useState } from 'react';
import { FilledButton } from '../../../../components/Buttons';
import { CompanyLicense } from './CompanyLicense';
import { get } from '../../../../libs/Requests';
import { InViewSpinner } from '../../../../components/Spinners/InViewSpinner';
import { useNavigate } from 'react-router-dom';
import { ILicenseDTO } from '../../../../../../types/LicenseDTO';

export function CompanyLicenses() {
  const [fetchData, setFetchData] = useState<boolean>(true);
  const navigate = useNavigate();
  const [companyLicenses, setCompanyLicenses] = useState<ILicenseDTO[] | undefined>();
  const [error, setError] = useState('');

  function purchaseNewLicenseClicked() {
    navigate('/');
  }

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    get('/company/subscriptions', { signal })
      .then(async (companyPurchaseHistoryResponse) => {
        setCompanyLicenses(await companyPurchaseHistoryResponse.json());
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          return;
        }

        setError('Oops, some problems occurred during loading...');
      })
      .finally(() => {});

    setFetchData(false);

    return () => {
      abortController.abort();
    };
  }, [fetchData]);

  if (error) {
    return <>{error}</>;
  }

  if (!companyLicenses) return <InViewSpinner></InViewSpinner>;

  return (
    <>
      <div className="flex flex-col justify-between gap-4 py-4 md:flex-row">
        <div className="my-auto flex justify-between font-semibold md:space-x-4">
          <span>Total Number of Licenses Active:</span>
          <span>{companyLicenses.filter((lic) => lic.status.toLowerCase() === 'active').length}</span>
        </div>

        <div className="w-full md:w-1/4">
          <FilledButton onClick={purchaseNewLicenseClicked}>Purchase New License</FilledButton>
        </div>
      </div>

      {companyLicenses.map((companyLicense) => (
        <CompanyLicense
          key={companyLicense.id}
          subscription={companyLicense}
          refreshLicensesCallback={() => {
            setFetchData(true);
          }}></CompanyLicense>
      ))}
    </>
  );
}

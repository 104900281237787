import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { signOut } from '@aws-amplify/auth';
import { FullScreenSpinner } from '../../../../components/Spinners/FullScreenSpinner';
import { useUserData } from '../../../../hooks/useUserData';

export function LogoutPage() {
  const { status } = useUserData();
  const navigator = useNavigate();
  const location = useLocation();

  if (status === 'configuring' || status === 'authorized') {
    if (status === 'authorized') {
      signOut({ global: true })
        .then(() => {
          navigator('/auth/sign_in' + (location.search != null ? location.search : ''));
        })
        .catch(() => {
          navigator('/auth/sign_in' + (location.search != null ? location.search : ''));
        });
    }

    return <FullScreenSpinner></FullScreenSpinner>;
  }

  return (
    <Navigate
      to={'/auth/sign_in' + (location.search != null ? location.search : '')}
      state={{ location }}
      replace></Navigate>
  );
}

import React, { useState } from 'react';
import { Tier, TierWrapper } from '../Tier';
import BorderedContainer from '../../../../components/Containers/BorderedContainer';
import logo from '../../../../assets/mint_medical_logo.svg';
import { FilledButton } from '../../../../components/Buttons';
import { Link, useNavigate } from 'react-router-dom';
import { ContactSalesTeamForm } from '../ContactSalesTeamForm/ContactSalesTeamForm';
import { StoreProductTierDTO } from '../../../../types/StoreProduct';
import { InViewSpinner } from '../../../../components/Spinners/InViewSpinner';
import { Description } from '../../../../components/Description';
import { useProductData } from '../../../../hooks/useProductData';
import { getOrderTargetUrl } from '../../../order/routes';

export function Tiers() {
  const { productData, getCurrencySymbol, getTierName } = useProductData({ productId: 'mintrex', lang: 'en' });

  const navigator = useNavigate();
  const [contactSalesTeamFormOnScreen, setContactSalesTeamFormOnScreen] = useState(false);
  const [selectedTier, setSelectedTier] = useState<StoreProductTierDTO>();
  const [seats, setSeats] = useState<string>('');
  const [fullPrice, setFullPrice] = useState(0);

  function handleSeatsChange(value: string) {
    const numberedValue = value && value.length ? parseInt(value) : 0;

    setSeats(numberedValue ? numberedValue.toString() : '');

    if (numberedValue === 0) {
      setSelectedTier(undefined);
    } else {
      const newTier = productData.tiers
        .filter((t) => {
          return (
            (!t.seatsRange!.to && numberedValue >= t.seatsRange.from) ||
            (numberedValue >= t.seatsRange.from && numberedValue <= t.seatsRange!.to!)
          );
        })
        .pop();

      if (selectedTier?.id != newTier?.id) {
        setSelectedTier(newTier);
      }

      if (newTier && newTier!.priceByMonth!) {
        setFullPrice(parseInt(newTier!.priceByYear!) * numberedValue);
      }
    }
  }

  function handleContactSalesTeam() {
    setContactSalesTeamFormOnScreen(!contactSalesTeamFormOnScreen);
  }

  function handleCloseContactSalesTeamForm() {
    setContactSalesTeamFormOnScreen(!contactSalesTeamFormOnScreen);
  }

  function handleSubmissionSent() {
    setTimeout(() => {
      setContactSalesTeamFormOnScreen(!contactSalesTeamFormOnScreen);

      handleSeatsChange('');
    }, 2000);
  }

  function handleGetStartedClicked() {
    navigator(getOrderTargetUrl('mintrex', parseInt(seats)));
  }

  if (productData.tiers.length == 0) {
    return (
      <>
        <div className="my-10 flex flex-col">
          <InViewSpinner></InViewSpinner>
        </div>
      </>
    );
  }

  return (
    <>
      <Description>{productData.description}</Description>

      <div className="space-y-2">
        {contactSalesTeamFormOnScreen && (
          <ContactSalesTeamForm
            onCloseClick={handleCloseContactSalesTeamForm}
            onSubmissionSent={handleSubmissionSent}></ContactSalesTeamForm>
        )}

        <TierWrapper>
          {productData.tiers.map((t) => (
            <Tier
              label={t.label}
              selected={selectedTier?.id === t.id}
              currencySymbol={getCurrencySymbol()}
              tierContent={t.content}
              key={t.id}
              id={t.id}
              seatsRange={t.seatsRange}
              priceByMonth={t.priceByMonth}></Tier>
          ))}
        </TierWrapper>

        <div className="mx-auto space-y-2 pt-2 md:pt-10">
          <div className="mx-auto w-full">
            <BorderedContainer>
              <div className="">
                <div className="flex flex-col justify-between self-start md:flex-row">
                  <img
                    className="w-3/4 self-center py-4 md:w-1/6 md:self-start"
                    src={logo}></img>
                </div>
                <div className="flex flex-row justify-between self-start">
                  <span className=" my-auto flex flex-col text-wrap pr-1 text-base font-semibold text-mint-medical-grey md:flex-row">
                    Enter number of users for your plan
                  </span>
                  <input
                    id="seats"
                    min={0}
                    placeholder="0"
                    autoFocus={true}
                    className="w-12 rounded-md border-2 border-mint-medical-green-lighter p-2 text-center focus:border-mint-medical-green focus:outline-none md:w-24"
                    value={seats}
                    onChange={(event) => {
                      handleSeatsChange(event?.target?.value);
                    }}
                    type="number"></input>
                </div>
                <div className={!selectedTier?.priceByMonth ? 'hidden' : ''}>
                  <div
                    className={
                      selectedTier
                        ? 'my-auto flex w-full flex-row justify-between self-start py-2'
                        : 'my-auto hidden w-full flex-row justify-between self-start'
                    }>
                    <span className="pl-0 text-base font-semibold text-mint-medical-grey md:pl-4">
                      {productData.name} | {getTierName(parseInt(seats))}
                    </span>
                    <span className={selectedTier?.priceByMonth ? 'text-base font-semibold' : 'hidden'}>
                      {getCurrencySymbol()}
                      {selectedTier?.priceByMonth?.replace('.00', '')} per user/month
                    </span>
                  </div>
                  <div className="flex justify-between py-2">
                    <span className="my-auto text-base font-semibold text-mint-medical-grey">Total</span>
                    <span className="text-base font-semibold">
                      {!selectedTier?.priceByMonth ? <>Contact us for pricing</> : ''}
                      {getCurrencySymbol()}
                      {fullPrice} {productData.currency.toUpperCase()} billed annually
                    </span>
                  </div>
                </div>
              </div>
            </BorderedContainer>
          </div>
          <div className=" flex w-full flex-col">
            <span className="mx-auto w-full p-4 text-center font-semibold text-mint-medical-grey md:w-4/5">
              This offering is brought to you by Mint Medical Inc, USA. Please see our{' '}
              <Link
                className=" text-mint-medical-green hover:underline"
                target="blank"
                to="/document/terms_and_conditions">
                Terms and Conditions
              </Link>{' '}
              and our{' '}
              <Link
                className=" text-mint-medical-green hover:underline"
                target="blank"
                to="/document/privacy_policy">
                Privacy Policy
              </Link>
              .
            </span>
            <div className="mx-auto w-full md:max-w-md">
              <FilledButton
                disabled={!selectedTier}
                onClick={selectedTier?.priceByMonth ? handleGetStartedClicked : handleContactSalesTeam}>
                {!selectedTier || selectedTier?.priceByMonth ? 'Get Started' : 'Contact Sales Team'}
              </FilledButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// Importing helper modules
import React, { useEffect } from 'react';
import { useMemo, useState } from 'react';
import QuillEditor from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FilledButton } from '../../../../components/Buttons';
import { get, post } from '../../../../libs/Requests';
import { FullScreenSpinner } from '../../../../components/Spinners/FullScreenSpinner';
import { Input } from '../../../../components/Input';
import { InViewSpinner } from '../../../../components/Spinners/InViewSpinner';

export interface EditorProps {
  documentId?: string;
  pathToVisit?: string;
  onSave?: () => void;
}

interface DocumentDTO {
  documentId: string;
  documentTitle: string;
  documentContent: string;
}

const Editor = (props: EditorProps) => {
  const [spinner, setSpinner] = useState(false);
  const [documentPath, setDocumentPath] = useState(`/document/${props.documentId ? props.documentId : ''}`);
  const [document, setDocument] = useState<DocumentDTO>({
    documentId: '',
    documentTitle: '',
    documentContent: ''
  });

  useEffect(() => {
    if (!props.documentId) {
      return;
    }

    const abortController = new AbortController();
    const signal = abortController.signal;

    get(`/admin/document/${props.documentId}`, { signal })
      .then(async (companyResponse) => {
        const _ = (await companyResponse.json()) as DocumentDTO;

        setDocument({
          ..._
        });
      })
      .catch(() => {});

    return () => {
      abortController.abort();
    };
  }, []);

  async function handleSave() {
    setSpinner(true);

    try {
      await post(
        props.documentId ? `/admin/document/${props.documentId}` : `/admin${documentPath}`,
        JSON.stringify({
          content: document?.documentContent,
          title: document?.documentTitle
        })
      );

      props.onSave && props.onSave();
    } catch (err) {
      console.log(err);
    }

    setSpinner(false);
  }

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'blockquote'],
          [{ color: ['#434549', '#0AA078'] }],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link'],
          ['clean']
        ],
        handlers: {}
      },
      clipboard: {
        matchVisual: true
      }
    }),
    []
  );

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'clean'
  ];

  function handlePathChange(newPath: string) {
    if (newPath.indexOf('/document/') === -1) {
      newPath = '/document/';
    }

    setDocumentPath(`${newPath}`);
  }

  function handleTitleChange(newTitle: string) {
    if (document.documentTitle === newTitle) {
      return;
    }

    setDocument({
      ...document!,
      documentTitle: newTitle
    });
  }

  function handleContentChange(value: string) {
    if (document.documentContent === value) {
      return;
    }

    setDocument({
      ...document!,
      documentContent: value
    });
  }

  return (
    <div className="space-y-2">
      {props.documentId && !document.documentId && <InViewSpinner></InViewSpinner>}

      {document.documentId.length || !props.documentId?.length ? (
        <>
          <div className="flex flex-col space-y-2">
            <Input
              id="title"
              value={document?.documentTitle}
              label="Title"
              onChange={(e) => {
                handleTitleChange(e.target.value);
              }}></Input>
            <Input
              id="url"
              value={documentPath}
              label="URL"
              onChange={(e) => {
                handlePathChange(e.target.value);
              }}></Input>
          </div>
          <div className="flex h-4/6 w-full">
            {spinner && <FullScreenSpinner></FullScreenSpinner>}
            <QuillEditor
              className="h-full w-full"
              theme="snow"
              value={document!.documentContent!}
              formats={formats}
              modules={modules}
              onChange={handleContentChange}
            />
          </div>
          <FilledButton onClick={handleSave}>Save</FilledButton>
        </>
      ) : null}
    </div>
  );
};

export default Editor;
